import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { FormDataBuilder, useFileUpload } from "../../../Sdk";
import { useBoats } from "../Boat/useBoats";
import { FormSection } from "../Form/FormsElements";
import { AppScreen, CardContainer, CardElement, CenterSection, Padder, PageHeader, PrimaryButton, WideContainer } from "../Helpers/Elements";
import { BIcons } from "../Helpers/Icons";
import { BlockingOverlay, Overlay } from "../Helpers/Overlay";
import { Toolbar } from "../Helpers/Toolbar";

export type TUploadFileToObjectType = "boats" | "users";
export type UploadFilesArgs = {
    parentid: string,
    type: TUploadFileToObjectType
}
export const UploadFiles = () => {
    const params = useParams<UploadFilesArgs>();

    const upload = useFileUpload();
    const nav = useNavigate();

    const boatsOps = useBoats();

    const screenTitle = (() => {
        if (params?.type == "boats") {
            const b = boatsOps.store?.[params?.parentid ?? ""];
            return "Filer för  " + b?.name + " (" + b.manufacturer + " " + b.model + ")";
        } else {
            return "Filer";
        }
    })();


    const [src, setSrc] = useState<File | undefined>(undefined);

    const [files, setFiles] = useState<File[]>([]);
    const [fileSize, setFileSize] = useState<number>(0);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

    const uploadFiles = async () => {
        const formData = new FormDataBuilder();
        files.forEach(f => formData.appendFile(f));
        formData.appendParentId(params?.parentid ?? "parentId");
        formData.appendAttribute("attr0", false);
        formData.appendAttribute("attr1", 456);
        formData.appendAttribute("attr2", "Hello!");
        formData.appendAttribute("attr3", { a: 1, b: false, c: "X" });

        const res = await upload.upload(formData.getFormData());
        if (res.success) {
            setFiles([]);
        }

        setFiles([]);
        setFileSize(0);
        setUploadSuccess(true)
    }

    return (
        <AppScreen screenTitle={screenTitle}>
            <WideContainer>
                <BlockingOverlay visible={upload.isUploading} />
                <CenterSection>
                    <PageHeader label={screenTitle} />
                    <Toolbar items={[
                        { id: "goback", label: "Tillbaka", onPress: () => { nav(-1) }, icon: BIcons.chevron_left },
                    ]} />
                    <Padder size="10px" />
                    <FormSection>

                        <div className="file-upload">

                            <input type="file" id="file-input" multiple onChange={e => {
                                if (!e.target.files) {
                                    return;
                                }
                                const fs: File[] = Array.from(e.target.files);
                                const sz = fs.reduce((partialSum, a) => partialSum + a.size, 0);
                                setFileSize(sz);

                                setFiles(fs);
                                setUploadSuccess(false);
                            }
                            } />
                            <label htmlFor="file-input">
                                <i className="fa-solid fa-arrow-up-from-bracket"></i>
                                Välj filer...
                            </label>
                            {(!(files?.length > 0) && uploadSuccess != true) && (
                                <div className="num-of-files">Inga filer valda</div>
                            )}

                            {(files?.length > 0) && (
                                <div className="num-of-files">{files?.length} filer valda, totalt : {(fileSize / (1024)).toFixed(1)} kb</div>
                            )}
                            {uploadSuccess && (
                                <div className="num-of-files">Filerna Laddades upp</div>
                            )}

                            <CardContainer>
                                {files?.map(f => (
                                    <CardElement key={f?.name} className="file-card">
                                        <div>
                                            {f?.name}
                                        </div>
                                        <div>
                                            {(f?.size / 1024).toFixed(1)} kb
                                        </div>

                                    </CardElement>
                                ))}
                            </CardContainer>
                        </div>

                        <PrimaryButton marginTop="20px" label={"Ladda upp"} onClick={uploadFiles} disabled={!(files.length > 0)} />
                    </FormSection>

                </CenterSection>
            </WideContainer>
        </AppScreen>
    )
}

