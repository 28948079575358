import { useEffect, useState } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IBoat } from "../../../Sdk";
import { AppRoutes } from "../../config/AppRoutes";
import { DisplayField, DisplayImage, FormSection } from "../Form/FormsElements";
import { AppScreen, CenterSection, Padder, PageHeader, Scrollable, SecondaryButton, WideContainer } from "../Helpers/Elements";
import { BIcons } from "../Helpers/Icons";
import { Toolbar } from "../Helpers/Toolbar";
import { useBoats } from "./useBoats";
export type BoatDetailsArgs = { id: string }
export const BoatDetailsView = () => {

    const params = useParams<BoatDetailsArgs>();
    const boatId = params?.id as string;
    const boat = useBoats();
    const selected = boat?.boat;
    const [model, setModel] = useState<IBoat>({});

    const nav = useNavigate();

    useEffect(() => {
        if (boatId) {
            boat.selectBoat(boatId);
        }
    }, []);

    useEffect(() => {
        setModel(selected ?? {})
    }, [selected]);


    const onEdit = () => {
        nav(AppRoutes.getBoatdetailEdit(boatId));
    }
    const onDocs = () => {
        nav(AppRoutes.getBoatdetaildDcuments(boatId));
    }


    return (
        <AppScreen screenTitle="Båt" removePadding={true}>
            <CenterSection>
                <Scrollable
                    head={(<WideContainer>
                        <PageHeader label={"Båt"}
                            menuItems={[
                                { id: "edit", label: "Redigera", onClick: onEdit },
                                { id: "docs", label: "Dokument", onClick: onDocs },
                                { id: "addimage", label: "Lägg till bild", onClick: () => { nav(AppRoutes.getUploadImageToObject("boats", model?.id ?? "")) } },
                                { id: "service", label: "Händelser", onClick: () => { alert("Visa alla händelser, service osv.") } },
                                { id: "goback", label: "Tillbaka", onClick: () => { nav(-1) } },
                            ]}
                        />
                        <Toolbar items={[
                            // { id: "edit", label: "Ändra", onPress: onEdit, icon: BIcons.edit },
                            // { id: "goback", label: "Tillbaka", onPress: () => { nav(-1) }, icon: BIcons.chevron_left },
                            { id: "addimage", label: "Bilder", onPress: () => { nav(AppRoutes.getUploadImageToObject("boats", model?.id ?? "")) }, icon: BIcons.plus },
                            { id: "docs", label: "Dokument", onPress: onDocs, icon: BIcons.file },
                            { id: "edit", label: "Ändra", onPress: onEdit, icon: BIcons.edit },
                        ]} />
                    </WideContainer>)}
                >
                    <WideContainer>
                        <FormSection>
                            <DisplayImage url={model?.image ?? boat?.defaultImageUrl} />
                            <DisplayField label={"Registreringsnummer"} value={model?.registrationCode} />
                            <DisplayField label={"Namn"} value={model?.name} />
                            <DisplayField label={"Tillverkare"} value={model?.manufacturer} />
                            <DisplayField label={"Modell"} value={model?.model} />
                            <DisplayField label={"Beskrivning"} value={model?.description} />
                        </FormSection>
                        <Padder></Padder>
                    </WideContainer>
                </Scrollable>
            </CenterSection>
        </AppScreen>
    )

}