import Resizer from "react-image-file-resizer";

const DEFAULT_MAX_WIDTH = 1920;
const DEFAULT_MAX_HEIGHT = 1920;
const DEFAULT_QUALITY = 70;

export type ResizeImageOptions = {
    maxWidth?: number,
    maxHeight?: number,
    quality?: number
}
export const useResizeImage = (options?: ResizeImageOptions) => {
    const resizeFile = (file: File) => new Promise((resolve) => {
        Resizer.imageFileResizer(
            file, // file
            options?.maxWidth ?? DEFAULT_MAX_WIDTH, // maxWidth
            options?.maxHeight ?? DEFAULT_MAX_HEIGHT, // maxHeight
            "JPEG", // compressFormat
            options?.quality ?? DEFAULT_QUALITY, // quality
            0, // rotation
            (uri) => { resolve(uri); }, // responseUriFunc
            "file" // outputType : "base64" | "file" | "blob"
        );
    });

    return {
        resizeFile: async (f: File): Promise<File> => {
            const fs = await resizeFile(f);
            return fs as File;
        },
        resizeFiles: async (fs: File[]): Promise<File[]> => {
            var resized: File[] = await Promise.all(fs.map(async (item: File): Promise<File> => {
                return (await resizeFile(item) as File);
            }));
            return resized;
        },
    }
}