import { useCallback, useEffect, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { IBoat, useFetchHandler } from "../../../Sdk";
import { EditFieldText, FormSection } from "../Form/FormsElements";
import { AppScreen, CenterSection, PageHeader, PrimaryButton, SecondaryButton, WideContainer } from "../Helpers/Elements";
import { Toolbar } from "../Helpers/Toolbar";
import { useKeyboardShortcut } from "../Hooks/useKeyboardShortcut";
import { useBoats } from "./useBoats";

export const AddBoat = () => {


    const nav = useNavigate();
    const boat = useBoats();
    const [model, setModel] = useState<IBoat>({});
    const f = useFetchHandler();

    const getRegistrationCode = async () => {
        const reg = await f.getJson<string>("/api/boat/getnewid");
        setModel({ ...model, registrationCode: reg?.data });
    }

    useEffect(() => {
        if (!model?.registrationCode) {
            getRegistrationCode();
        }
    }, [])

    useEffect(() => {
        console.log(".....")
    }, [model])



    const onSave = async () => {
        const s = await boat.onBoatAdd({ ...model });
        setModel({});
        nav(-1);
    };

    const onCancel = async () => { nav(-1); }


    useKeyboardShortcut(onSave, ["s"], [model]);
    useKeyboardShortcut(onCancel, ["z"]);

    return (
        <AppScreen screenTitle="Lägg till ny båt">
            <CenterSection>
                <WideContainer>
                    <PageHeader label="Lägg till ny båt"
                        menuItems={[
                            { id: "cancel", label: "Ångra", onClick: onCancel },
                            { id: "save", label: "Spara", onClick: onSave, },
                        ]}
                    />

                    <FormSection>

                        <div className="frm">
                            <label onClick={getRegistrationCode}>Nytt registreringsnummer</label>
                            <div className="data">
                                {model?.registrationCode}
                            </div>
                        </div>

                        <EditFieldText label="Namn" onChange={e => setModel({ ...model, name: e })} autoFocus={true} />
                        <EditFieldText label="Tillverkare" onChange={e => setModel({ ...model, manufacturer: e })} />
                        <EditFieldText label="Modell" onChange={e => setModel({ ...model, model: e })} />
                        {/* {JSON.stringify(model)} */}
                    </FormSection>

                    <PrimaryButton label={"Spara"} onClick={onSave} marginTop="20px" />
                    <SecondaryButton label={"Ångra"} onClick={onCancel} marginTop="20px" />

                </WideContainer>
            </CenterSection>
        </AppScreen>
    )

}