import { useEffect, useState } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { IBoat } from "../../../Sdk";
import { AppRoutes } from "../../config/AppRoutes";
import { DisplayField, DisplayImage, EditFieldText, EditFieldTextArea, FormSection } from "../Form/FormsElements";
import { AppScreen, CenterSection, Padder, PageHeader, PrimaryButton, Scrollable, SecondaryButton, WideContainer } from "../Helpers/Elements";
import { BIcons } from "../Helpers/Icons";
import { Toolbar } from "../Helpers/Toolbar";
import { useKeyboardShortcut } from "../Hooks/useKeyboardShortcut";
import { useBoats } from "./useBoats";

export type BoatDetailsArgs = { id: string }
export const BoatDetails = () => {

    const params = useParams<BoatDetailsArgs>();
    const boatId = params?.id as string;
    const boat = useBoats();
    const selected = boat?.boat;
    const [model, setModel] = useState<IBoat>({});

    const nav = useNavigate();

    useEffect(() => {
        if (boatId) {
            boat.selectBoat(boatId);
        }
    }, []);

    useEffect(() => {
        setModel(selected ?? {})
    }, [selected]);

    const onSave = async () => {
        const s = await boat.onBoatAdd(model);
        setModel({});
        nav(-1);
    }

    const onCancel = () => {
        nav(-1);
    }
    const onDelete = () => {
        boat.deleteBoat(model?.id ?? "");
        nav(-2);
    }

    useKeyboardShortcut(onSave, ["s"], [model]);

    const title = !!boatId ? "Ändra båt" : "Lägg till ny båt";

    return (
        <AppScreen screenTitle={title} removePadding={true}>
            <CenterSection>
                <Scrollable head={(<WideContainer>
                    <PageHeader label={title}
                        menuItems={[
                            { id: "save", label: "Spara", onClick: onSave },
                            { id: "cancel", label: "Ångra", onClick: onCancel },
                            { id: "delete", label: "Radera", onClick: onDelete },
                        ]}
                    />
                    <Toolbar items={[
                        // { id: "goback", label: "Tillbaka utan att spara", onPress: () => { nav(-1) }, icon: BIcons.chevron_left },
                        // { id: "addimage", label: "Lägg till bild", onPress: () => { nav(AppRoutes.getUploadImageToObject("boats", model?.id ?? "")) }, icon: BIcons.plus },
                        { id: "cancel", label: "Ångra", onPress: onCancel, },
                        { id: "save", label: "Spara", onPress: onSave, },
                    ]} />
                </WideContainer>)}>

                    <WideContainer>
                        <FormSection>
                            <DisplayImage url={model?.image ?? boat?.defaultImageUrl} />
                            <DisplayField label={"Registreringsnummer"} value={model?.registrationCode} />
                            <EditFieldText label="Namn" defaultValue={model?.name} onChange={e => setModel({ ...model, name: e })} />
                            <EditFieldText label="Tillverkare" defaultValue={model?.manufacturer} onChange={e => setModel({ ...model, manufacturer: e })} />
                            <EditFieldText label="Modell" defaultValue={model?.model} onChange={e => setModel({ ...model, model: e })} />
                            <EditFieldTextArea label="Beskrivning" defaultValue={model?.description} onChange={e => setModel({ ...model, description: e })} minHeight="300px" />
                        </FormSection>
                        <PrimaryButton label={"Spara"} onClick={onSave} marginTop="20px" />
                        <SecondaryButton label={"Ångra"} onClick={onCancel} marginTop="20px" />
                        <Padder />
                    </WideContainer>
                </Scrollable>
            </CenterSection>
        </AppScreen>
    )

}