import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { FormDataBuilder, useFileUpload } from "../../../Sdk";
import { useBoats } from "../Boat/useBoats";
import { FormSection } from "../Form/FormsElements";
import { AppScreen, CardContainer, CardElement, CenterSection, Padder, PageHeader, PrimaryButton, SecondaryButton, WideContainer } from "../Helpers/Elements";
import { BIcon, BIcons } from "../Helpers/Icons";
import { BlockingOverlay, BlockingOverlaySpinner, Overlay } from "../Helpers/Overlay";
import { Toolbar } from "../Helpers/Toolbar";
import { useResizeImage } from "./useResizeImage";
import { IRestCreatedResponse } from "../../../Sdk/IO/api/useFetchHandler";
import { useConsoleLog } from "../../../Sdk/Data/hooks/useConsoleLog";


export type UploadImageArgs = {
    parentid?: string,
    data?: any
}

export const UploadImage = () => {
    const params = useParams<UploadImageArgs>();
    const ui = useImageUpload(params);
    const nav = useNavigate();
    const boat = useBoats().store?.[params?.parentid ?? ""];
    const screenTitle = "Lägg till bild till " + (boat?.name ?? "");

    const upload = async () => {
        const response = await ui.uploadFiles<{ params: any }>({ params: params?.data });
        if (!!response?.message) {
            alert(response?.message)
        }
    };

    const tools = ui.hasImage ? [
        { id: "cancel", label: "Ångra", onPress: ui.onCancel, icon: BIcons.times },
        { id: "save", label: "Ladda upp", onPress: upload, icon: BIcons.upload },

    ] : [
        { id: "goback", label: "Tillbaka", onPress: () => { nav(-1) }, icon: BIcons.chevron_left },
    ];

    return (
        <AppScreen screenTitle={screenTitle}>

            <BlockingOverlaySpinner
                //visible={true}
                visible={ui.isResizing || ui.isUploading}
                progress={ui.progress}
            />
            <WideContainer>
                <CenterSection>
                    <PageHeader label={screenTitle} />
                    <Toolbar items={tools} />
                    <Padder size="10px" />
                    <FormSection>

                        <div className="image-upload">

                            {(!ui.hasImage) && (
                                <div className="image-upload-btns">
                                    <div>
                                        <input type="file" id="camera-input" onChange={ui.onFileSelected}
                                            accept="image/jpeg" capture="environment" />
                                        <label htmlFor="camera-input" className="camera-input">
                                            <BIcon icon={BIcons.camera} size="3x" />
                                        </label>
                                    </div>
                                    <div>
                                        <input type="file" id="gallery-input" onChange={ui.onFileSelected}
                                            accept="image/jpeg" multiple={true} />
                                        <label htmlFor="gallery-input" className="camera-input">
                                            <BIcon icon={BIcons.images} size="3x" />
                                        </label>
                                    </div>
                                </div>
                            )}

                            <div>
                                {(ui.uploadSuccess && false) && (
                                    <div className="num-of-files">Bilden Laddades upp</div>
                                )}
                            </div>

                            <div>
                                {ui.files?.map(f => (
                                    <ImageCard key={f?.name} f={f} removeFile={() => ui.removeFile(f)} />
                                ))}
                            </div>
                        </div>

                        {ui.hasImage && (
                            <div>
                                <PrimaryButton marginTop="20px" label={"Ladda upp"} onClick={upload} disabled={!ui.hasImage} />
                                <SecondaryButton marginTop="20px" label={"Ångra"} onClick={ui.onCancel} disabled={!ui.hasImage} />
                            </div>
                        )}
                    </FormSection>

                    <Padder />
                </CenterSection>
            </WideContainer>
        </AppScreen>
    )
}

const ImageCard = ({ f, removeFile }: { f: File, removeFile(): void }) => {

    const [show, setShow] = useState<boolean>(false);


    return (


        <div key={f?.name} className="image-card">
            <div className="remove" onClick={removeFile}>
                <BIcon icon={BIcons.times} size="lg" />
            </div>
            <div onClick={() => setShow(true)}>
                <img className="image" src={URL.createObjectURL(f)} loading="lazy" />
            </div>

            <div className="filesize">
                {(f?.size / 1024).toFixed(1)} kb
            </div>

            {show && (
                <Overlay darker={true}>
                    <div className="image-preview" onClick={() => setShow(false)}>
                        <img src={URL.createObjectURL(f)} className="image-preview-img" loading="lazy" />
                    </div>
                </Overlay>
            )}
        </div>

    )
}

type ImageUploadResponseItem = {
    parentId: string
    imageIds: string[]
    totalBytes: number,
    request?: string
}

const useImageUpload = (params: UploadImageArgs) => {

    const upload = useFileUpload();

    const [progress, setProgress] = useState<number>(0);
    const [files, setFiles] = useState<File[]>([]);
    const [fileSizeStart, setFileSizeStart] = useState<number>(0);
    const [fileSize, setFileSize] = useState<number>(0);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
    const resizeImage = useResizeImage();
    const [resizing, setResizing] = useState<boolean>(false);

    type TNoData = { nodata: true }; // 
    const uploadFiles = async <T = TNoData>(data?: T): Promise<IRestCreatedResponse<ImageUploadResponseItem>> => {
        setProgress(0);
        const formData = new FormDataBuilder();
        files.forEach(f => formData.appendFile(f));
        formData.appendParentId(params?.parentid ?? "parentId");
        formData.appendAttribute("data", data ?? { nodata: true });
        //setProgress(0.3);
        const res = await upload.upload<ImageUploadResponseItem>(formData.getFormData(), e => setProgress(e));
        if (res.success) {
            setFiles([]);
            console.log(res);
            console.log(res?.response?.totalBytes);
        }

        setFiles([]);
        setFileSize(0);
        setUploadSuccess(true);
        setProgress(1);
        return res;
    }

    const onFileSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        setProgress(0);
        setResizing(true);
        const fs: File[] = Array.from(e.target.files).filter(f => f.name.toLowerCase().includes(".jpg") || f.name.toLowerCase().includes(".jpeg"));

        //var resized: File[] = await resizeImage.resizeFiles(fs);
        var resized: File[] = [];
        for (let i = 0; i < fs.length; i++) {
            resized[i] = await resizeImage.resizeFile(fs[i]);
            setProgress((1 * i) / fs.length);
        }


        const szStart = fs.reduce((partialSum, a) => partialSum + a.size, 0);
        const sz = resized.reduce((partialSum, a) => partialSum + a.size, 0);

        console.log("reduced by:", ((szStart - sz) / szStart).toFixed(2))

        console.log("resized: ", resized);
        setFiles([...files, ...resized]);
        setUploadSuccess(false);
        setResizing(false);

        setProgress(1);
    }

    const onCancel = async () => {
        setFiles([]);
        setFileSize(0);
        setUploadSuccess(false)
    }

    const hasImage = !!(files?.length > 0);

    return {
        hasImage: hasImage,
        isResizing: resizing,
        isUploading: upload.isUploading,
        progress: progress,

        files: files,
        uploadSuccess: uploadSuccess,

        uploadFiles: uploadFiles,
        onFileSelected: onFileSelected,
        removeFile: (f: File) => {
            setFiles(files.filter(m => m.name !== f.name))
        },
        onCancel: onCancel,
    }
}
