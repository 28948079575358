import { IAction } from "./Types";

const RESET_APP_REDUCER = "RESET_APP_REDUCER";
const CHANGE_APPSTATE = "CHANGE_APPSTATE";
const CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE = "CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE";

export type AppReducerType = {
    showNotAuthorizedMessage: boolean,
    appState: {}
}

export const appReducerDefaultState: AppReducerType = {
    showNotAuthorizedMessage: false,
    appState: {},
};

const appReducer = (state = {
    ...appReducerDefaultState,
}, action: IAction) => {
    switch (action.type) {
        case RESET_APP_REDUCER: { state = { ...appReducerDefaultState }; break; }

        case CHANGE_APPSTATE: { state = { ...state, appState: action.payload }; break; }
        case CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE: { state = { ...state, showNotAuthorizedMessage: action.payload }; break; }


        default: { return state; }
    }
    return state;
};

export {
    appReducer,
    RESET_APP_REDUCER,
    CHANGE_APPSTATE,
    CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE
}