import { useDispatch } from "react-redux";
import { DateTime } from "luxon";
import { CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE } from "../../Data/Redux/appReducer";
import { TokenStore } from "../../Platform/TokenStore";
import { usePlatform } from "../../Platform/PlatformProvider";
import { useUser } from "../../Data/hooks/useUser";
import { CHANGE_RETRIEVING_ACCESSTOKEN } from "../../Data/Redux/userReducer";
import { Config } from "../../../components/config/Config";
import axios, { AxiosHeaders, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from "axios";


interface IFetchHandler {
    getJson<T>(url: string, options?: IGetOptions): Promise<IFetchResult<T>>,
    postJson<T>(url: string, postObj?: any, options?: IPostOptions): Promise<IFetchResult<T>>,
    delete<T>(url: string): Promise<IFetchResult<T>>,
    patchJson<T>(url: string, postObj?: any, options?: IPatchOptions): Promise<IFetchResult<T>>,
    updateAccessToken(): Promise<boolean>,
    formUpload<T>(url: string, formData: FormData, progress?: (e: number) => void): Promise<IRestCreatedResponse<T>>,
}

interface IFetchOptions {
    dontAuthorize?: boolean,
    dontRefreshToken?: boolean
}

interface IFetchResult<T> {
    responseCode: number,
    ok: boolean,
    data: T
}
export interface IRestCreatedResponse<T> {
    message: string | undefined,
    success: boolean,
    responseCode?: number,
    response?: T
}

interface IPatchOptions extends IFetchOptions { }
interface IPostOptions extends IFetchOptions { }
interface IGetOptions extends IFetchOptions { }

const useFetchHandlerHelpers = () => {
    const platform = usePlatform();
    const dispatch = useDispatch();
    const user = useUser();

    const baseHeaders = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "app-version": Config.appVersion,
        "app-name": Config.appName
    };
    const baseHeadersForms = {
        "Accept": "application/json",
        "app-version": Config.appVersion,
        "app-name": Config.appName
    };

    const getFullUrl = (url: string) => {
        if (url?.startsWith("https")) {
            return url;
        } else {
            return platform?.apiDomain + url;
        }
    };

    const handleResponse = async <T>(response: Response): Promise<IFetchResult<T>> => {
        if (response.status === 401) {
            dispatch({ type: CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE, payload: true });
            user.logout();
            return { responseCode: response.status, data: null as any, ok: false };
        }

        const data = await response.json();
        return { responseCode: response.status, data: data, ok: response.ok };
    };

    const handleFormUploadResponse = async <T>(response: AxiosResponse): Promise<IRestCreatedResponse<T>> => {
        if (response.status === 401) {
            dispatch({ type: CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE, payload: true });
            user.logout();
            return { success: false, message: response.statusText, responseCode: response.status };
        }
        const ret = response.data;
        return { ...ret, responseCode: response.status };
    };

    return {
        baseHeaders: baseHeaders,
        baseHeadersForms: baseHeadersForms,
        getFullUrl: getFullUrl,
        handleResponse: handleResponse,
        handleFormUploadResponse: handleFormUploadResponse,
    }
}

export const useFetchHandler = (): IFetchHandler => {
    const helper = useFetchHandlerHelpers();
    const dispatch = useDispatch();

    const getAuthBearerToken = async (dontRefreshToken?: boolean) => {
        const accessToken = (await TokenStore.getInstance().getTokens())?.accessToken ?? "";
        const accessTokenExpires = (await TokenStore.getInstance().getTokens())?.accessTokenExpires ?? 0;

        if (dontRefreshToken) {
            return accessToken;
        }

        // current epoch
        const now = Math.round(DateTime.now().toSeconds());

        // is token expired or non-existent
        const expired = !accessToken || accessTokenExpires < now;

        const seconds_left = accessTokenExpires - now;
        if (expired || seconds_left < 10) {

            const refreshToken = (await TokenStore.getInstance().getTokens())?.refreshToken;
            const refreshTokenExpires = (await TokenStore.getInstance().getTokens())?.refreshTokenExpires ?? 0;

            if (refreshTokenExpires < now) {
                return accessToken;
            }

            return await fetchAccesstoken();
        }
        return accessToken;
    }

    const fetchAccesstoken = async () => {
        const refreshToken = (await TokenStore.getInstance().getTokens())?.refreshToken;

        dispatch({ type: CHANGE_RETRIEVING_ACCESSTOKEN, payload: true });
        // Fetch accesstoken
        const response = await fetch(helper.getFullUrl("/api/auth/refreshaccesstoken"), {
            method: "PATCH",
            headers: helper.baseHeaders,
            body: JSON.stringify({ refreshToken: refreshToken })
        });

        const data: { token: string, tokenExpires: number } = await response.json();

        // save new accesstoken.
        TokenStore.getInstance().setAccessToken({
            accessToken: data?.token,
            accessTokenExpires: data?.tokenExpires
        });

        dispatch({ type: CHANGE_RETRIEVING_ACCESSTOKEN, payload: false });

        return data?.token;
    }

    return {
        updateAccessToken: async (): Promise<boolean> => {
            const token = await fetchAccesstoken();
            return !!token;
        },
        getJson: async <T>(url: string, options?: IGetOptions): Promise<IFetchResult<T>> => {
            const dontAuthorize = options?.dontAuthorize;
            const dontRefreshToken = options?.dontRefreshToken;
            const auth = dontAuthorize ? {} : { "Authorization": "Bearer " + (await getAuthBearerToken(dontRefreshToken)) }

            const response = await fetch(helper.getFullUrl(url), {
                method: "GET",
                headers: { ...helper.baseHeaders, ...auth } as HeadersInit,
            });

            return await helper.handleResponse(response);
        },
        postJson: async <T>(url: string, postObj?: any, options?: IPostOptions): Promise<IFetchResult<T>> => {
            const dontAuthorize = options?.dontAuthorize;
            const dontRefreshToken = options?.dontRefreshToken;
            const auth = dontAuthorize ? {} : { "Authorization": "Bearer " + (await getAuthBearerToken(dontRefreshToken)) }

            const response = await fetch(helper.getFullUrl(url), {
                method: "POST",
                headers: { ...helper.baseHeaders, ...auth } as HeadersInit,
                body: JSON.stringify(postObj ?? {})
            });

            return await helper.handleResponse(response);
        },
        patchJson: async <T>(url: string, postObj?: any, options?: IPatchOptions): Promise<IFetchResult<T>> => {
            const dontAuthorize = options?.dontAuthorize;
            const dontRefreshToken = options?.dontRefreshToken;
            const auth = dontAuthorize ? {} : { "Authorization": "Bearer " + (await getAuthBearerToken(dontRefreshToken)) }

            const response = await fetch(helper.getFullUrl(url), {
                method: "PATCH",
                headers: { ...helper.baseHeaders, ...auth } as HeadersInit,
                body: JSON.stringify(postObj ?? {})
            });

            return await helper.handleResponse(response);
        },
        delete: async <T>(url: string): Promise<IFetchResult<T>> => {
            const auth = { "Authorization": "Bearer " + (await getAuthBearerToken()) };

            const response = await fetch(helper.getFullUrl(url), {
                method: "DELETE",
                headers: { ...helper.baseHeaders, ...auth } as HeadersInit,
            });

            return await helper.handleResponse(response);
        },
        formUpload: async <T>(url: string, formData: FormData, progress?: (e: number) => void): Promise<IRestCreatedResponse<T>> => {

            const auth = { "Authorization": "Bearer " + (await getAuthBearerToken()) };
            const headers = { ...helper.baseHeadersForms, ...auth } as any as AxiosHeaders;

            const config: AxiosRequestConfig<FormData> = {
                headers: headers,
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    if (progressEvent.total != undefined && progressEvent.total > 0) {
                        const complete = progressEvent.loaded / progressEvent.total
                        if (progress) {
                            progress(complete);
                        }
                    }
                }
            };

            const response = await axios.post(helper.getFullUrl(url), formData, config);

            return await helper.handleFormUploadResponse(response);



        }
    }
}