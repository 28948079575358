import { Helmet } from "react-helmet";
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { store, useKeepAccessTokenUpdated, useUserDataProvider, useKeepRefreshTokenUpdated, ThemeProvider, TThemeType } from './Sdk';
import { Layout } from './components/ui/UILayout/Layout';
import { Provider } from 'react-redux';
import { PlatformProvider } from './Sdk';
import { CurrentPlatformImplementations } from './components/implementations/Platform';
import { useState } from "react";

const App = () => {
  const platform = CurrentPlatformImplementations();
  const [theme, setTheme] = useState<TThemeType>("light");

  return (
    <BrowserRouter>
      <Provider store={store}>
        {/* Header */}
        <Helmet>
          <meta charSet="utf-8" />
          <title>boatado.com</title>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
            integrity="sha512-HK5fgLBL+xu6dm/Ii3z4xhlSUyZgTT9tuc/hSrtw6uzJOvgRr2a9jyxxT1ely+B+xFAmJKVSTbpM/CuL7qxO8w=="
            crossOrigin="anonymous" />
        </Helmet>

        {/* Body */}
        <ThemeProvider theme={theme}>
          <PlatformProvider platform={platform}>
            <UserStateProvider />
            <Layout />
          </PlatformProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

// Global Components
const UserStateProvider = () => {

  const refreshTokenUpdater = useKeepRefreshTokenUpdated();
  const accessTokenUpdater = useKeepAccessTokenUpdated();

  const userDataProvider = useUserDataProvider();

  return (null)
}

export default App;
