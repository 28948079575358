import { DateTime } from "luxon";

export interface IHelper {
    sleep: (time: number) => Promise<unknown>;
    isValidEmail: (email: string) => boolean;
    epochToYear: (epochSeconds: number) => number;
    epochToMonthKey: (epochSeconds: number) => number;
    epochToFormattedDate: (epochSeconds: number | undefined) => string;
    timeAgo: (epochSeconds: number) => string;
}

export const Helpers: IHelper = {
    sleep: (time: number) => new Promise(res => setTimeout(res, time, "done sleeping")),
    isValidEmail: (email: string): boolean => {
        if (!email) { return false; }
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        } else {
            return false;
        }
    },
    epochToYear: (epochSeconds: number) => {
        var dte = DateTime.fromSeconds(epochSeconds);
        if (dte) {
            return dte.year;
        } else {
            return 0
        };
    },
    epochToMonthKey: (epochSeconds: number) => {
        var dte = DateTime.fromSeconds(epochSeconds);
        if (dte) {
            return dte.year * 100 + dte.month;
        } else {
            return 0
        };
    },
    epochToFormattedDate: (epochSeconds: number | undefined) => {
        if (epochSeconds) {
            var dte = DateTime.fromSeconds(epochSeconds);
            return dte.toFormat("yyyy-LL-dd");
        } else {
            return "-"
        };
    },
    timeAgo: (epochSeconds: number) => {
        // console.log(epoch);
        if (!epochSeconds) { return ""; }

        const past = new Date(epochSeconds * 1000);
        const now = new Date();
        const diff = now.valueOf() - past.valueOf();

        const seconds = Math.floor(diff / (1000));
        const minutes = Math.floor(diff / (1000 * 60));
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
        const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

        if (years > 0) {
            return years === 1 ? "ett år sedan" : years + " år sedan";
        } else if (weeks > 0) {
            return weeks === 1 ? "en vecka sedan" : weeks + " veckor sedan";
        } else if (days > 0) {
            return days === 11 ? "en dag sedan" : days + " dagar sedan";
        } else if (hours > 0) {
            return hours === 1 ? "en timme sedan" : hours + " timmar sedan";
        } else if (minutes > 0) {
            return minutes === 1 ? "en minut sedan" : minutes + " minuter sedan";
        } else if (seconds > 0) {
            return seconds === 1 ? "en sekund sedan" : seconds + " sekunder sedan";
        } else {
            return "nu"
        }
    },
}

export const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}