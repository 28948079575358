import { CreateLoginTokenType, ItemContainer, ListContainer, RestCreatedReponse, RestDeletedReponse, SimpleUserType } from "./Types";
import { IRefreshTokenType } from "../../Data/hooks/useUserManagement";
import { useFetchHandler } from "./useFetchHandler";

export const useUserManagementApi = () => {
    const f = useFetchHandler();
    return {
        // Roles
        addRole: async (userId: string, role: string): Promise<boolean> => {
            const response = await f.patchJson<boolean>("/api/users/" + userId + "/addrole", { role: role });
            return response?.data;
        },
        removeRole: async (userId: string, role: string): Promise<boolean> => {
            const response = await f.patchJson<boolean>("/api/users/" + userId + "/removerole", { role: role });
            return response?.data;
        },
        getRoles: async (): Promise<ListContainer<string>> => {
            const response = await f.getJson<ListContainer<string>>("/api/users/roles");
            return response?.data;
        },

        // Users
        addUser: async (email: string, firstName: string, lastName: string,): Promise<RestCreatedReponse<SimpleUserType>> => {
            const postObj = {
                email: email,
                firstName: firstName,
                lastName: lastName
            }
            const response = await f.postJson<RestCreatedReponse<SimpleUserType>>("/api/users/create", postObj);
            return response?.data;
        },
        getUsers: async (): Promise<ListContainer<SimpleUserType>> => {
            const response = await f.getJson<ListContainer<SimpleUserType>>("/api/users");
            return response?.data;
        },
        getUser: async (id: string): Promise<ItemContainer<SimpleUserType>> => {
            const response = await f.getJson<ItemContainer<SimpleUserType>>("/api/users/" + id);
            return response?.data;
        },
        deleteUser: async (id: string): Promise<RestDeletedReponse> => {
            const response = await f.delete<RestDeletedReponse>("/api/users/" + id);
            return response?.data;
        },

        // LoginToken
        createLoginToken: async (id: string): Promise<CreateLoginTokenType> => {
            const response = await f.postJson<CreateLoginTokenType>("/api/users/" + id + "/createlogintoken");
            return response?.data;
        },

        // LoginToken
        getUserRefreshtokens: async (userId: string): Promise<IRefreshTokenType[]> => {
            const response = await f.getJson<IRefreshTokenType[]>("/api/users/" + userId + "/refreshtokens");
            return response?.data;
        },
        deleteUserRefreshtoken: async (userId: string, tokenId: string): Promise<boolean> => {
            const response = await f.delete<boolean>("/api/users/" + userId + "/refreshtokens/" + tokenId);
            return response?.data;
        },
    }
}
