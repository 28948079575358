import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IBoat } from "../../../Sdk";
import { AppRoutes } from "../../config/AppRoutes";
import { AppScreen, CardContainer, CardElement, Padder, PageHeader, Scrollable, WideContainer } from "../Helpers/Elements";
import { BIcon, BIcons } from "../Helpers/Icons";
import { WebDialog } from "../Helpers/WebDialog";
import { useBoats } from "../Boat/useBoats"
import { useKeyboardShortcut } from "../Hooks/useKeyboardShortcut";

//import React, { ReactNode, useState } from 'react';
export const Home = () => {
    const boat = useBoats();
    const nav = useNavigate();


    const addNew = async () => nav(AppRoutes.addboat);

    useKeyboardShortcut(addNew, ["+"]);


    return (
        <AppScreen screenTitle="Hem" removePadding={true}>

            <Scrollable
                head={(
                    <WideContainer>
                        <PageHeader label="Mina båtar"
                            menuItems={[
                                { id: "add", label: "Lägg till ny båt 1", onClick: addNew },
                            ]}
                        />
                    </WideContainer>
                )}
            >
                <WideContainer>
                    <CardContainer>
                        {boat?.boats?.map(m => (
                            <BoatCard key={m?.id} item={m} />
                        ))}
                        <CardElement className="add-card" onClick={() => { nav(AppRoutes.addboat) }}>
                            <div className="card-center center">
                                <div className="plus-container">
                                    <BIcon icon={BIcons.plus} size="2x" />
                                </div>
                            </div>
                        </CardElement>
                    </CardContainer>
                </WideContainer>
                <Padder />
            </Scrollable>


        </AppScreen>
    )
}

const BoatCard = ({ item }: { item: IBoat }) => {
    const boat = useBoats();
    const nav = useNavigate();
    return (
        <div className="card-boat" onClick={() => nav(AppRoutes.getBoatdetailView(item?.id))} >
            <div>
                <div className="card-image">
                    <img className="boat-image" src={item?.image ?? boat?.defaultImageUrl} loading="lazy" />
                </div>
            </div>
            <div className="boat-info">
                <div className="boat-name" title={item?.name}>
                    {item?.name}
                </div>

                <div className="boat-type" title={item?.manufacturer}>
                    {item?.manufacturer}&nbsp;
                    {item?.model}
                </div>

                <div className="boat-description" title={item?.manufacturer}>
                    {item?.description}
                </div>
            </div>
        </div>
    )
}