import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoginStateType, useApplicationSettings } from "../../../Sdk";
import { InputHelpers } from "../Form/InputHelpers";

export const NotLoggedInLayout = ({ loginState, onTokenSubmit, error }: { loginState?: LoginStateType, onTokenSubmit(e: string): void, error?: string }) => {

    const app = useApplicationSettings();
    const showNotAuthorizedMessage = app.showNotAuthorizedMessage;
    const [tokenValue, setTokenValue] = useState<string>("");
    const working = loginState === "loggingin";
    const nav = useNavigate();
    const [cookieConsent, setCookieConsent] = useState<boolean>(true);
    const [search] = useSearchParams();
    useEffect(() => {
        const tokenCode = search.get("token");
        if (tokenCode) {
            setTokenValue(tokenCode);
        }

        if (tokenCode && cookieConsent) {
            setTokenValue(tokenCode);
            onTokenSubmit(tokenCode);
            nav("/");
        }
    }, [, cookieConsent])



    return (
        <div id="ui-login">

            {(!showNotAuthorizedMessage) && (
                <div className={'login-box ' + (working ? " disabled" : "")} style={{ height: "190px" }}>
                    <h3 className='center'>
                        {loginState === "loggingin" && (<span>Loggar in...</span>)}
                        {loginState !== "loggingin" && (<span>Logga in</span>)}
                    </h3>
                    <div className='frm'>
                        <label>Token</label>
                        <input
                            data-testid="login-input"
                            type={"text"} id="token" name="token"
                            {...InputHelpers.textInput(tokenValue, e => setTokenValue(e), () => onTokenSubmit(tokenValue))}
                            placeholder="Kod"
                            autoComplete="off"
                            aria-autocomplete="none"
                            autoCapitalize='none'
                        />
                        {!!error && (
                            <div className='login-failed'>
                                {error}
                            </div>
                        )}
                    </div>
                    <div className='frm center p10'>

                        <span >Tillåt cookies?
                            <input
                                data-testid="login-checkbox"

                                id='cookie'
                                type={"checkbox"} checked={cookieConsent} onChange={e => setCookieConsent(e.target.checked)}
                                style={{
                                    display: "inline-block",
                                    width: "20px"
                                }}
                            />
                        </span>
                    </div>

                    <div className='frm center'>
                        <button
                            data-testid="login-button"
                            role="button"
                            className='w50 btn-primary'
                            onClick={() => onTokenSubmit(tokenValue)} disabled={working || cookieConsent != true}>
                            Login
                        </button>
                    </div>
                </div>
            )}

            {showNotAuthorizedMessage && (
                <div className={'login-box'}>
                    <h3 className='center'>
                        Du har loggats ut
                    </h3>

                    <div className='p10'></div>

                    <div className='frm center'>
                        <button className='w50 btn-primary' onClick={() => app.dismissNotAuthorizedMessage()} disabled={working}>
                            OK
                        </button>
                    </div>
                </div>
            )}


        </div>
    )
}