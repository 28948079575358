import { combineReducers } from 'redux';

import { appReducer } from './appReducer';
import { boatReducer } from './boatReducer';
import { userManagementReducer } from './userManagementReducer';
// import { RdxStore } from './Types';
import { userReducer } from './userReducer';

const reducers = combineReducers({
    app: appReducer,
    user: userReducer,
    boat: boatReducer,
    userManagement: userManagementReducer
});

export { reducers }