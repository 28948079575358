import { Component, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Overlay } from './Overlay';

const portalRoot = document.getElementById("portal");

export const Portal = (
    { children, portalId = 'portal' }:
        { children: ReactNode, portalId?: string }) => {

    const [container] = useState(() => {
        const el = document.createElement("div");
        el.id = portalId;
        return el
    })

    useEffect(() => {
        document.getElementById(portalId)?.appendChild(container)
        return () => {
            document.getElementById(portalId)?.removeChild(container);
        }
    }, [])

    return ReactDOM.createPortal(children, container)
}

export const PortalContainer = ({ portalId, children, visible = true, onClosed = undefined, onOutSideClick }: { portalId?: string, children: ReactNode, visible: boolean, onClosed?(): void, onOutSideClick?(): void }) => {

    const [showPortal, setShowPortal] = useState<boolean>(visible);

    useEffect(() => {
        if (visible) {
            setShowPortal(true);
            document.getElementById("overlay")?.classList.remove("hidden");
        } else {
            document.getElementById("overlay")?.classList.add("hidden");
            setTimeout(() => {
                setShowPortal(false);
                if (onClosed) {
                    onClosed()
                }
            }, 400);

        }
    }, [visible]);


    if (!showPortal) {
        return (null);
    }

    // const onOutSideClick = () => {
    //     if (visible) {
    //         setShowPortal(false);
    //         if (onClosed) {
    //             onClosed()
    //         }
    //     }
    // }

    return (
        <Portal portalId={portalId}>
            <Overlay onOutSideClick={onOutSideClick}>
                {children}
            </Overlay>
        </Portal>
    )
}


export const BottomDialog = () => {
    const [hidden, setHidden] = useState(true);
    if (hidden) {
        return (null)
    }
    return (
        <Portal>
            <div id='bottomdialog' onClick={() => setHidden(true)}>
                <h3>
                    öasldaölsdasdas
                </h3>
                <p>
                    daösldkaösd köasdk asödk asödkla
                </p>

            </div>
        </Portal>
    )
}