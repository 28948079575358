import React, { Children, createContext, ReactNode, useContext, useState } from 'react';



// Context
export type TThemeType = "light" | "dark";
export const ThemeContext = createContext<TThemeType | undefined>(undefined);


// Provider
export const ThemeProvider = ({ theme, children }: { theme: TThemeType, children: ReactNode }) => {
    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    )
}

// Hook
export const useTheme = () => {
    const theme = useContext(ThemeContext);
    if (!theme) {
        throw new Error("No theme avalaible!")
    }
    return theme;
}