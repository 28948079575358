import { useSelector, useDispatch } from "react-redux";
import { CHANGE_BOAT_BOAT, IBoat, CHANGE_BOAT_ALLBOATS } from "../Redux/boatReducer";
import { RdxStore } from "../Redux/Types";

export const useBoatsData = () => {
    const allBoats = useSelector((state: RdxStore) => state.boat.allBoats);
    const boat = useSelector((state: RdxStore) => state.boat.boat);
    const dispatch = useDispatch();

    const selectBoat = (id: string) => {
        const n = allBoats?.[id];
        dispatch({ type: CHANGE_BOAT_BOAT, payload: n });
    }
    const onBoatAdd = async (model: IBoat) => {
        const n = {
            ...model,
            id: model?.id ?? (new Date()).valueOf().toString(),
        };
        const change = { ...allBoats, [n?.id]: n };
        dispatch({ type: CHANGE_BOAT_ALLBOATS, payload: change });
        console.log("boat added: ", model)
        return true;
    }

    const deleteBoat = (id: string) => {
        let n = { ...allBoats };
        if (n?.[id]) {
            delete n[id];
        }
        dispatch({ type: CHANGE_BOAT_ALLBOATS, payload: n });
    }

    return {
        store: allBoats,
        boat: boat,
        boats: Object.keys(allBoats ?? {}).map(key => allBoats?.[key]),

        selectBoat: selectBoat,
        onBoatAdd: onBoatAdd,
        deleteBoat: deleteBoat,

    }
}