import { ReactNode } from "react"
import { BottomMenu } from "./BottomMenu"
import { TopMenu } from "./TopMenu"

export const LoggedInLayout = ({ children, loggedin }: { children: ReactNode, loggedin: boolean }) => {

    if (!loggedin) {
        return (null)
    }

    return (
        <div id="ui-main">
            {/* Top: #ui-main-top */}
            <TopMenu />

            {/* Main: #ui-main-content */}
            <div id='ui-main-content'>
                {children}
            </div>

            {/*  #ui-bottom-menu */}
            <div id='ui-bottom-menu'>
                <BottomMenu />
            </div>
        </div>
    )
}
