import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserRoleTypes } from "../../Authentication/useUserApi";
import { RdxStore } from "../Redux/Types";
import { CHANGE_COMMAND_RELOAD_USERDATA, CHANGE_LOGIN_TOKEN, CHANGE_COMMAND_LOGOUT } from "../Redux/userReducer";

export type LoginStateType = "loggedin" | "notloggedin" | "loggingin";
// ########################################################################
// ############################### Userdata ###############################
// ########################################################################

export const useUserLoggedIn = () => {
    const user = useSelector((store: RdxStore) => store.user.user);
    return !!user?.loggedin
}

export const useUser = () => {
    const dispatch = useDispatch();
    const user = useSelector((store: RdxStore) => store.user.user);
    const loggingIn = useSelector((store: RdxStore) => store.user.loggingIn);
    const loggingInResponse = useSelector((store: RdxStore) => store.user.loggingInResponse);
    const isRetrievingAccesstoken = useSelector((store: RdxStore) => store.user.isRetrievingAccesstoken);

    // Loginstate

    return {
        ...user,
        hasRole: (role: UserRoleTypes) => { return !!user?.roles?.find(m => m === role); },
        loginState: (!!loggingIn && !user?.id) ? "loggingin" : !!user?.loggedin ? "loggedin" : "notloggedin" as LoginStateType,
        loggingInResponse: loggingInResponse,
        loggingIn: loggingIn || isRetrievingAccesstoken,
        loginWithToken: async (token: string) => {
            dispatch({ type: CHANGE_LOGIN_TOKEN, payload: token });
        },
        logout: () => {
            dispatch({ type: CHANGE_COMMAND_LOGOUT });
        },
        reloadUserData: () => {
            dispatch({ type: CHANGE_COMMAND_RELOAD_USERDATA, payload: true });
        }
    }
}




