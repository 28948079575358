import { usePlatform } from "../Platform/PlatformProvider";
import { useFetchHandler } from "../IO/api/useFetchHandler";

// Roles from boatado.se.lib.Models.UserRoles
export type UserRoleTypes = "USERADMIN" | "SYSADMIN" | "TECH" | "DEVELOPER" | undefined;

export type CheckAuthResponseType = {
    loggedin?: boolean,
    id?: string,
    email?: string,
    roles?: UserRoleTypes[],
    phoneNumber?: string
}

export type LoginWithTokenResponse = {
    success?: boolean,
    token?: string,
    tokenExpires?: number,
    refreshToken?: string,
    refreshTokenExpires?: number,
    message: string
}

export type TotateRefreshtokenResponse = {
    refreshToken: string,
    refreshTokenExpires: number
}


export const useUserApi = () => {
    const platform = usePlatform();
    const fetchHandler = useFetchHandler();
    return {
        checkAuth: async (): Promise<CheckAuthResponseType> => {
            const data = await fetchHandler.getJson<CheckAuthResponseType>("/api/auth/me", { dontAuthorize: false, dontRefreshToken: false });
            const resp: CheckAuthResponseType = data?.data;
            return resp;
        },
        logout: async (refreshToken: string): Promise<boolean> => {
            const data = await fetchHandler.patchJson<boolean>("/api/auth/logout", { refreshtoken: refreshToken, platform: platform?.platformName });
            const resp: boolean = data?.data;
            return resp;
        },
        postToken: async (token: string): Promise<LoginWithTokenResponse> => {
            const data = await fetchHandler.patchJson<LoginWithTokenResponse>("/api/auth/loginwithtoken", { token: token, platform: platform?.platformName }, { dontAuthorize: true, dontRefreshToken: true });
            const success = data?.data;
            return success;
        },
        rotateRefreshtoken: async (refreshToken: string): Promise<TotateRefreshtokenResponse> => {
            const data = await fetchHandler.patchJson<TotateRefreshtokenResponse>("/api/auth/rotaterefreshtoken", { refreshToken: refreshToken, platform: platform?.platformName });
            const d: TotateRefreshtokenResponse = data?.data;
            return d;
        },
        expireRefreshToken: async (oldRefreshToken: string): Promise<boolean> => {
            const data = await fetchHandler.patchJson<boolean>("/api/auth/expirerefreshtoken", { refreshToken: oldRefreshToken, platform: platform?.platformName });
            return data?.data;
        }
    }
}
