import { IMessageBoxes } from "../../Sdk";

const MessageBoxes: IMessageBoxes = {
    // type: "error","warning", "info". default: "info"

    info: (txt: string, keepOpen?: boolean) => {
        alert(txt);
    },
    warning: (txt: string, keepOpen?: boolean) => {
        alert(txt);
    },
    successAlert: (success: boolean, txt: string) => {
        alert(txt);
    },
    confirmWithCallback: (txt: string, onOk: () => void, onCancel?: () => void): void => {
        const answer = window.confirm(txt);
        if (answer && onOk) {
            onOk()
        } else if (onCancel) {
            onCancel()
        }
    },

    prompt: (txt: string) => {
        const ret = window.prompt(txt);
        if (ret) { return ret; }
        else { return ""; }
    }
}

export { MessageBoxes };

