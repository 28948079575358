import { env } from "process"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { Helpers, useTheme, useUser } from "../../../Sdk"
import { AppRoutes } from "../../config/AppRoutes"
import { Config } from "../../config/Config"
import { MyrefreshTokens } from "../Account/MyrefreshTokens"
import { AppScreen, CenterSection, Padder, PageHeader, WideContainer } from "../Helpers/Elements"
import { BIcon, BIcons } from "../Helpers/Icons"

// import React, { ReactNode, useState } from 'react';
export const Settings = () => {
    const nav = useNavigate();
    const user = useUser();
    const timeAgo = Helpers.timeAgo(Config.buildTime);
    const timeAgoDate = Helpers.epochToFormattedDate(Config.buildTime);
    const theme = useTheme();

    return (
        <AppScreen screenTitle="Inställningar">
            <WideContainer>
                <CenterSection>
                    <PageHeader label="Inställningar" />

                    <SettingsContainer header="Jag">
                        <SettingsItems label="Profile" description="Min profil" onClick={() => { nav(AppRoutes.myprofile) }} />
                        <SettingsItems label="Användare" description="Alla användare" onClick={() => { nav(AppRoutes.users) }} />
                        <SettingsItems label="Filer" description="Uppladdade filer" onClick={() => { nav(AppRoutes.uploadfiles) }} />
                    </SettingsContainer>

                    <SettingsContainer header="Misc">
                        <SettingsItems label="Ladda om" description="Ladda om app" onClick={() => window.location.reload()} />
                        <SettingsItems label="Tema" description={theme?.toUpperCase()} />
                        {user.hasRole("SYSADMIN") && (
                            <>
                                <SettingsItems label="PWA" description="app.boatado.com" onClick={() => window.open("https://app.boatado.com")} />
                                <SettingsItems label="GitHub" description="boatado.se.app" onClick={() => window.open("https://github.com/jonasoberg/boatado.se.app")} />
                            </>
                        )}
                    </SettingsContainer>

                    <SettingsContainer header="Build">
                        <SettingsItems label={"Branch: " + Config.buildBranch} description={"Hash: " + Config.buildHash} />
                        <SettingsItems label={"Build Date: " + timeAgoDate} description={timeAgo} />
                    </SettingsContainer>

                    <button className="btn-primary w100" onClick={() => user.logout()}>
                        Logga ut
                    </button>

                    {/* <MyrefreshTokens /> */}
                </CenterSection>
            </WideContainer>
        </AppScreen >
    )
}

const SettingsContainer = ({ header, children }: { header: string, children: ReactNode }) => {



    return (
        <div className="settings-container">
            <div className="header">
                {header}
            </div>
            {children}
        </div>
    )
}
const SettingsItems = ({ label, description, onClick }: { label: string, description?: string, onClick?(): void }) => {



    return (
        <div className="settings-item" onClick={onClick}>
            <div>
                <div className="label">{label}</div>
                <div className="description">{description}</div>
            </div>
            {onClick && (
                <div className="ico">
                    <BIcon icon={BIcons.chevron_right} />
                </div>
            )}
        </div>
    )
}