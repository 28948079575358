
export class FormDataBuilder {
    files: File[];
    form: any;

    constructor() {
        this.form = {};
        this.files = [];
    }

    appendFile(file: File) {
        this.files.push(file);
    }
    appendParentId(parentId: string) {
        this.form.parentId = parentId;
    }
    appendAttribute(name: string, value: any) {
        this.form[name] = value;
    }

    getFormData(): FormData {
        let frm = new FormData();
        this.files.forEach(f => frm.append("file", f));
        frm.append("form", JSON.stringify(this.form));
        return frm;
    }
}