import { useEffect, useState } from "react";
import { useFetchHandler } from "../IO/api/useFetchHandler";
import { TokenStore } from "../Platform/TokenStore";

export const useTicker = (intervalSeconds: number) => {
    const [time, setTime] = useState(Date.now());
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), intervalSeconds * 1000);
        return () => { clearInterval(interval); };
    }, []);
    return time;
}

export const useRunOnSchedule = (intervalSeconds: number, action: () => void, runOnstart: boolean = false) => {
    const tickInterval = useTicker(intervalSeconds);
    const [started] = useState(Math.round((new Date()).valueOf() / 1000));
    useEffect(() => {
        const now = Math.round((new Date()).valueOf() / 1000);
        // Not first intervalSeconds / 2 seconds.
        const first_halfof_interval_passed = now > (started + (intervalSeconds / 20));
        if (runOnstart || first_halfof_interval_passed) {
            if (!!action) {
                action();
            }
        }
    }, [tickInterval]);

    return tickInterval;
}







