import axios from "axios";
import { useState } from "react";
import { delay } from "../Misc/Helpers";
import { IRestCreatedResponse, useFetchHandler } from "./api/useFetchHandler";

export interface IFileToUpload {
    name: string,
    size: number
    type: string
}
export interface IFileToUploadOptions { action: string, data: any }
export interface IFileUploadResponse<T> {
    id: string,
    success: boolean,
    message?: string,
    data: T
}

export const useFileUpload = () => {
    const f = useFetchHandler();
    const [uploading, setUploading] = useState<boolean>(false);
    const [failedUpload, setFailedUpload] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    return {
        isUploading: uploading,
        failedUpload: failedUpload,
        errorMessage: errorMessage,
        upload: async <T = boolean>(formData: FormData, progress?: (e: number) => void): Promise<IRestCreatedResponse<T>> => {
            let error: string = "";
            setUploading(true);
            setFailedUpload(false);
            setErrorMessage("");

            try {
                if (!formData) {
                    return { success: false, message: "Missing formdata" };
                }

                const res = await f.formUpload<T>("/api/upload", formData, progress);
                setUploading(false);
                return res;

                // await delay(3000);
            } catch (e) {
                error = e as string;
                setErrorMessage(error);
                setFailedUpload(true);
            }

            setUploading(false);
            return { success: false, message: error };
        },
    }
}