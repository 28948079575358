import { ReactNode } from "react"
import { Padder } from "./Elements";
import { BIcon, BIcons } from "./Icons";

export const Overlay = ({ children, onOutSideClick, darker }: { children?: ReactNode, onOutSideClick?(): void, darker?: boolean }) => {
    const onOverlayClick = (e: any) => {
        if (e.target?.id === "overlay" && !!onOutSideClick) {
            onOutSideClick();
        }
    }
    return (
        <div id="overlay" className={darker ? "darker" : "dark"} onClick={onOverlayClick}>
            {children}
        </div>
    )
}

export const BlockingOverlay = ({ visible }: { visible: boolean }) => {
    if (visible !== true) {
        return (null)
    }
    return (
        <div id="overlay" className="dark">
        </div>
    )
}
export const BlockingOverlaySpinner = ({ visible, progress }: { visible: boolean, progress?: number }) => {
    if (visible !== true) {
        return (null)
    }

    const hasProgress = progress !== undefined;
    const w = Math.round((progress ?? 0) * 100) + "%";

    return (
        <div id="" className="overlay dark">
            <div className="spinner">
                <div>
                    <BIcon icon={BIcons.spinner} size="7x" />
                    {hasProgress && (
                        <>
                            <Padder size="20px" />
                            <div className="progress-bar">
                                <div className="progress" style={{ width: w }}>

                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}


export const OverlayTransparent = ({ children }: { children: ReactNode }) => {
    return (
        <div id="overlay">
            {children}
        </div>
    )
}