import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helpers, IRefreshTokenType, ListContainer, RestCreatedReponse, SimpleUserType, useFetchHandler, useUser, useUserLoggedIn, useUserManagement } from "../../../Sdk";
import { AppRoutes } from "../../config/AppRoutes";
import { DisplayField, FormSection } from "../Form/FormsElements";
import { AppScreen, CenterSection, PageHeader, PrimaryButton, SecondaryButton, WideContainer } from "../Helpers/Elements";
import { BlockingOverlay, Overlay } from "../Helpers/Overlay";
import { Toolbar } from "../Helpers/Toolbar";
import { WebDialog } from "../Helpers/WebDialog";

export type UserDetailViewArgs = { id: string }
export const UserDetailView = () => {
    const nav = useNavigate();

    const params = useParams<UserDetailViewArgs>();
    const userId = params?.id ?? "";

    const ui = useUserManagement();
    const user = ui.users?.[userId];

    const [tokens, setTokens] = useState<IRefreshTokenType[]>([])

    useEffect(() => {
        if (userId) {
            ui.getUser(userId);
            ui.getUserRefreshtokens(userId).then(s => setTokens(s));
        }
    }, [, userId]);

    const [deleting, setDeleting] = useState<boolean>(false);

    return (
        <AppScreen screenTitle="Profil">
            <CenterSection>
                <WideContainer>
                    <PageHeader label={(user?.firstName ?? "") + " " + (user?.lastName ?? "")}
                        menuItems={[
                            { id: "token", label: "Skapa loginkod", onClick: () => nav(AppRoutes.getCreateUserLoginTokenView(userId)) },
                            { id: "edit", label: "Ändra användare", onClick: () => { } },
                            { id: "roles", label: "Ändra roller", onClick: () => { } },
                            {
                                id: "delete", label: "Radera", onClick: async () => {
                                    setDeleting(true);
                                    const success = await ui.deleteUser(userId);
                                    setDeleting(false);
                                    if (success) {
                                        nav(-1);
                                    }
                                }
                            },
                        ]}
                    />
                    <FormSection>
                        <DisplayField label={"Email"} value={user?.email} />
                        <DisplayField label={"FirstName"} value={user?.firstName} />
                        <DisplayField label={"LastName"} value={user?.lastName} />
                        <DisplayField label={"Roles"} value={user?.roles?.join(", ")} />
                        <DisplayField label={"Created"} value={Helpers.epochToFormattedDate(user?.createdEpoch)} />
                        <DisplayField label={"Locale"} value={user?.uiLocale} />
                    </FormSection>
                    {/* <DebugTable items={tokens} /> */}

                    <table className="tbl">
                        {/* <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead> */}
                        <tbody>
                            {tokens?.map(token => (
                                <tr key={token?.id}>
                                    <td>{token?.remoteIp}</td>
                                    <td>{token?.userAgent}</td>
                                    <td>
                                        <button onClick={async () => {
                                            const success = await ui.deleteUserRefreshtoken(token?.userId, token?.id);
                                            if (success) {
                                                ui.getUserRefreshtokens(userId).then(s => setTokens(s));
                                            }

                                        }}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>

                    {/* <SecondaryButton label={"Tillbaka"} onClick={() => nav(-1)} marginTop="20px" /> */}


                </WideContainer>

            </CenterSection>
            <BlockingOverlay visible={deleting} />
        </AppScreen>
    )
}


const DebugTable = ({ items }: { items: any[] }) => {

    if (!items || !items?.length) {
        return (
            <div>
                Empty object
            </div>
        )
    }

    const fields = Object.keys(items?.[0]);


    return (
        <table className="tbl">
            <thead>
                <tr>
                    {fields?.map(f => (
                        <th key={f}>
                            {f}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {items?.map(item => (
                    <tr key={JSON.stringify(item)}>
                        {fields?.map(f => (
                            <td key={f}>
                                {item?.[f]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}