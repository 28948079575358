import { SimpleUserType } from "../../IO/api/Types";
import { IAction, IDataStoreObject } from "./Types";


const RESET_USERMANAGEMENT_REDUCER = "RESET_USERMANAGEMENT_REDUCER";
const CHANGE_USERMANAGEMENT_USERS = "CHANGE_USERMANAGEMENT_USERS";
const CHANGE_USERMANAGEMENT_ROLES = "CHANGE_USERMANAGEMENT_ROLES";


export type UserManagementReducerType = {
    users: IDataStoreObject<SimpleUserType>,
    roles: IDataStoreObject<string>,
}
export const userManagementDefaultState: UserManagementReducerType = {
    users: {},
    roles: {},
};


const userManagementReducer = (state = {
    ...userManagementDefaultState,
}, action: IAction) => {
    switch (action.type) {
        case RESET_USERMANAGEMENT_REDUCER: { state = { ...userManagementDefaultState }; break; }

        case CHANGE_USERMANAGEMENT_USERS: { state = { ...state, users: action.payload }; break; }
        case CHANGE_USERMANAGEMENT_ROLES: { state = { ...state, users: action.payload }; break; }

        default: { return state; }
    }
    return state;
};


export {
    userManagementReducer,
    RESET_USERMANAGEMENT_REDUCER,
    CHANGE_USERMANAGEMENT_USERS,
    CHANGE_USERMANAGEMENT_ROLES,
}