import { IAction, IDataStoreObject } from "./Types";
const RESET_BOAT_REDUCER = "RESET_BOAT_REDUCER";

const CHANGE_BOAT_ALLBOATS = "CHANGE_BOAT_ALLBOATS";
const CHANGE_BOAT_BOAT = "CHANGE_BOAT_BOAT";

export type BoatReducerType = {
    allBoats: IDataStoreObject<IBoat>,
    boat?: IBoat,
}

export interface IBoat {
    id?: string
    registrationCode?: string
    name?: string,
    image?: string,
    manufacturer?: string,
    model?: string,
    boatDocuments?: IDataStoreObject<IBoatDocument>,
    description?: string
}
export interface IBoatDocument {
    id?: string
    name?: string,
    createdEpoch?: number,
    public?: boolean
}

export const boatReducerDefaultState: BoatReducerType = {
    allBoats: {
        ["331656af-d00b-4280-a5f6-d8f397cd243b"]: {
            id: "331656af-d00b-4280-a5f6-d8f397cd243b",
            registrationCode: "5ZHC9VP9",
            name: "Tux",
            manufacturer: "Flipper",
            model: "HT 666",
            image: "/img/flipper666ht.jpg",
            description: "Flipper 620 i mycket fint skick! \r\nMotorn är en Suzuki 115hk från 2007 som går klockrent. \r\nPåkostad under de senaste 4 åren med bland annat nytt kapell \r\nny garmin plotter \r\nny kärra\r\nNytt bord \r\nPeke med stege i fören \r\nMatta osv.",
            boatDocuments: {
                "doc0": { id: "doc0", name: "Köpeavtal ", createdEpoch: 1511174350 },
                "doc1": { id: "doc1", name: "Garantibevis", createdEpoch: 1511174350, public: true },
                "doc2": { id: "doc2", name: "Service protokoll vår 2015", createdEpoch: 1511174350, public: true },
                "doc3": { id: "doc3", name: "Service protokoll höst 2015", createdEpoch: 1511174350, public: true },
                "doc5": { id: "doc5", name: "Reparation Motor juli 2016", createdEpoch: 1511174350, public: true },
                "doc4": { id: "doc4", name: "Service protokoll vår 2016", createdEpoch: 1511174350, public: true },
            }
        },
        ["526e5621-743c-4648-848a-0edd2b29fc0f"]: {
            id: "526e5621-743c-4648-848a-0edd2b29fc0f",
            registrationCode: "QDYI3CHA",
            name: "Ninna",
            manufacturer: "Hallberg-Rassy",
            model: "50 fot",
            image: "/img/hallberg-rassy-50.jpg",
            description: "HR37, BLÅ JUNGFRUN, är välutrustad och uppgraderad under åren. Hon har förvarats inomhus flera vintrar och under de senaste åren har hon seglats sparsamt. Finishen och inredningen är i gott skick och tygklädseln på dynor och madrasser samt heltäckningsmattor är i mycket fint skick.\r\nDenna HR37 har den grunda kölen på 1,60m. Detta innebär att båten har få begränsningar. Du kan segla runt bland vackra skär i den grundaste delen av skärgården eller gå för motor genom Europas kanaler. Eller varför inte korsa dom stora haven och segla jorden runt?\r\n\r\nDenna HR37 erbjuder allt i ett; lyx, komfort, säkerhet och tidlös design. Blå Jungfrun är en välbyggd och prisvärd långfärdsbåt som kan ta dig vart du vill.\r\n\r\nKey Facts:\r\n• Seldénmast med rullstor\r\n• Yanmar 4JH4E, 54 HP. \r\n• Bogpropeller\r\n• Elektriskt ankarspel\r\n• Ny sprayhood med sittbrunnskapell\r\n• Raymarine 12 plotter \r\n• MaxSea Computer navigator.\r\n• Raymarine autopilot \r\n• VHF, AIS, Radar \r\n• 7 kojplatser.\r\n• Badplattform med stege i aktern.\r\n• Grund köl, 1.60m.\r\n• Dieselvärmare (Webasto) med utblås i alla kabiner och salong.\r\n• Nya heltäckningsmattor i hela båten.\r\n• Nyligen installerade mantågsöppningar på båda sidor. \r\n• Peke med ankarrulle och stege.",
            boatDocuments: {
                "doc0": { id: "doc0", name: "Köpeavtal ", createdEpoch: 1511174350 },
                "doc1": { id: "doc1", name: "Garantibevis", createdEpoch: 1511174350, public: true },
                "doc2": { id: "doc2", name: "Service protokoll vår 2015", createdEpoch: 1511174350, public: true },
                "doc3": { id: "doc3", name: "Service protokoll höst 2015", createdEpoch: 1511174350, public: true },
                "doc5": { id: "doc5", name: "Reparation segel juli 2016", createdEpoch: 1511174350, public: true },
                "doc4": { id: "doc4", name: "Service protokoll vår 2016", createdEpoch: 1511174350, public: true },
            }
        },
        ["7101d669-ab1b-42d0-88aa-3f2df872dde7"]: {
            id: "7101d669-ab1b-42d0-88aa-3f2df872dde7",
            registrationCode: "YHP6Z2ZJ",
            name: "Vito",
            manufacturer: "TG",
            model: "7200 King Cruiser",
            image: "/img/tg7200kingcruiser.jpg",
            description: "",
            boatDocuments: {
                "doc1": { id: "doc1", name: "Garantibevis", createdEpoch: 1511174350, public: true },
            }
        },
    },
    boat: undefined,
};


const boatReducer = (state = {
    ...boatReducerDefaultState,
}, action: IAction) => {
    switch (action.type) {
        case RESET_BOAT_REDUCER: { state = { ...boatReducerDefaultState }; break; }

        case CHANGE_BOAT_ALLBOATS: { state = { ...state, allBoats: action.payload }; break; }
        case CHANGE_BOAT_BOAT: { state = { ...state, boat: action.payload }; break; }


        default: { return state; }
    }
    return state;
};

export {
    boatReducer,
    CHANGE_BOAT_ALLBOATS,
    CHANGE_BOAT_BOAT
}