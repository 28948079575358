import { useNavigate, useLocation } from "react-router-dom";
import { useUser, useUserLoggedIn } from "../../../Sdk";
import { AppRoutes } from "../../config/AppRoutes";
import { AccountDialog } from "../Account/AccountDialog";
import { AccessExpires, BoatNameGeneric } from "../Helpers/Elements";
// import { Overlay } from "./Helpers/Overlay";
import { BottomDialog } from "../Helpers/Portal";
import { useBoats } from "../Boat/useBoats";
import { Leftmenu } from "./LeftMenu";
import { BIcon, BIcons } from "../Helpers/Icons";
import { useEffect, useState } from "react";
import { useBottomMenu } from "./BottomMenu";

export const TopMenu = () => {
    const boat = useBoats()?.boat;
    const nav = useNavigate();
    const loc = useLocation();
    const routes = useBottomMenu().bottomMenuItems.map(m => m.route);

    const [backVisible, setBackVisible] = useState<boolean>(false);

    useEffect(() => {
        if (loc) {
            //console.log(loc?.pathname)
            if (routes.includes(loc?.pathname)) {
                setBackVisible(false)
            } else {
                setBackVisible(true)
            }
        }
    }, [loc])


    return (
        <div id='ui-main-top'>
            <div className="ui-main-top-upper">
                <div className="flexb40" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={backVisible ? () => nav(-1) : undefined}>
                    {backVisible && (
                        <BIcon icon={BIcons.chevron_left} size="lg" />
                    )}
                </div>

                <div className="flex1 center">
                    {<BoatNameGeneric boat={boat} />}
                </div>

                <div className="flexb40">
                    <BottomDialog />
                </div>
            </div>
            {/* <div className="ui-main-top-links">
                <div className="link">asdas</div>
                <div className="link">asdas</div>
                <div className="link">asdas</div>
                <div className="link">asdas</div>
            </div> */}
        </div>
    )
}
