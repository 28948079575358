import { CheckAuthResponseType } from "../../Authentication/useUserApi";

// ############################ CHANGES ############################
const RESET_USER_REDUCER = "RESET_USER_REDUCER";
const CHANGE_USER_STATE = "CHANGE_USER_STATE";
const CHANGE_LOGIN_TOKEN = "CHANGE_LOGIN_TOKEN";
const CHANGE_LOGGING_IN = "CHANGE_LOGGING_IN";
const CHANGE_LOGGING_IN_RESPONSE = "CHANGE_LOGGING_IN_RESPONSE";
const CHANGE_COMMAND_LOGOUT = "CHANGE_COMMAND_LOGOUT";
const CHANGE_RETRIEVING_ACCESSTOKEN = "CHANGE_RETRIEVING_ACCESSTOKEN";
const CHANGE_COMMAND_RELOAD_USERDATA = "CHANGE_COMMAND_RELOAD_USERDATA";


// ############################ Types ############################
export type UserReducerType = {
    user: UserType,         // user data
    loginToken?: string,    // loginToken passed from form or querystring
    loggingIn: boolean,     // is user logging in
    loggingInResponse?: LoggingInResponseType,     // is user logging in
    isRetrievingAccesstoken: boolean,

    // commands
    commandLogOut: boolean         // user clicked 'logout',
    commandReloadUserData: boolean

}
export type LoggingInResponseType = {
    success: boolean,
    message?: string
}
// -----------------------------------------------------------
export type UserType = CheckAuthResponseType & {
    name?: string | undefined,
    uiLocale?: string,
    fullName?: string
}

// ############################ Default Store ############################
const UserReducerStore: UserReducerType = {
    user: {
        loggedin: false
    },
    loginToken: undefined,
    loggingIn: false,
    loggingInResponse: undefined,
    isRetrievingAccesstoken: false,

    commandLogOut: false,
    commandReloadUserData: false,
}

// ############################ Reducer ############################
const userReducer = (state = { ...UserReducerStore }, action: any) => {
    switch (action.type) {

        case RESET_USER_REDUCER: { state = { ...UserReducerStore }; break; }

        case CHANGE_USER_STATE: { state = { ...state, user: action.payload }; break; }
        case CHANGE_LOGIN_TOKEN: { state = { ...state, loginToken: action.payload }; break; }
        case CHANGE_LOGGING_IN: { state = { ...state, loggingIn: action.payload }; break; }
        case CHANGE_LOGGING_IN_RESPONSE: { state = { ...state, loggingInResponse: action.payload }; break; }
        case CHANGE_COMMAND_LOGOUT: { state = { ...state, commandLogOut: true }; break; }
        case CHANGE_RETRIEVING_ACCESSTOKEN: { state = { ...state, isRetrievingAccesstoken: action.payload }; break; }
        case CHANGE_COMMAND_RELOAD_USERDATA: { state = { ...state, commandReloadUserData: action.payload }; break; }

        default: { return state; }
    }
    return state;
};

// ############################ export ############################
export {
    userReducer,
    RESET_USER_REDUCER,
    CHANGE_USER_STATE,
    CHANGE_LOGIN_TOKEN,
    CHANGE_LOGGING_IN,
    CHANGE_LOGGING_IN_RESPONSE,
    CHANGE_COMMAND_LOGOUT,
    CHANGE_RETRIEVING_ACCESSTOKEN,
    CHANGE_COMMAND_RELOAD_USERDATA,
}