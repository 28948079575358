import { LoginWithTokenResponse } from "../Authentication/useUserApi";

export interface ITokenPersistentStorage {
    persist(token: IRefreshToken): Promise<boolean>,
    read(): Promise<IRefreshToken>,
    clear(): Promise<boolean>
}

export interface IRefreshToken {
    refreshToken?: string,
    refreshTokenExpires?: number,
}
interface IAccessToken {
    accessToken?: string,
    accessTokenExpires?: number,
}

export interface ITokens extends IAccessToken, IRefreshToken { }
// ################### ITokenStore ###################
interface ITokenStore {
    setTokens(tokens: LoginWithTokenResponse): void,
    setAccessToken(token: IAccessToken): void,
    getTokens(): Promise<ITokens>,
    clearTokens(): void,
    init(store: ITokenPersistentStorage): Promise<boolean>,
    setRefreshTokens(token: IRefreshToken): Promise<boolean>
}
// ################### TokenStore ###################
export class TokenStore implements ITokenStore {
    // ----------------------- Singleton -----------------------
    private static instance: TokenStore;
    private constructor() { }
    public static getInstance(): TokenStore {
        if (!TokenStore.instance) {
            TokenStore.instance = new TokenStore();
        }
        return TokenStore.instance;
    }
    // ----------------------- PersistentStorage -----------------------
    private _persistentStorage?: ITokenPersistentStorage;

    public async init(storage: ITokenPersistentStorage): Promise<boolean> {
        this._persistentStorage = storage;
        await storage?.read();
        return true;
    }
    // ----------------------- Private variables -----------------------
    private _accessToken?: string;
    private _accessTokenExpires?: number = 0;

    public setTokens(tokens: LoginWithTokenResponse) {
        this._accessToken = tokens.token;
        this._accessTokenExpires = tokens.tokenExpires;
        // Save refreshTokens to store
        this._persistentStorage?.persist({
            refreshToken: tokens?.refreshToken,
            refreshTokenExpires: tokens?.refreshTokenExpires
        });
    }

    public async getTokens(): Promise<ITokens> {
        const refreshTokens = await this._persistentStorage?.read();

        return {
            accessToken: this._accessToken,
            accessTokenExpires: this._accessTokenExpires,
            refreshToken: refreshTokens?.refreshToken,
            refreshTokenExpires: refreshTokens?.refreshTokenExpires
        }
    }

    public setAccessToken(token: IAccessToken) {
        this._accessToken = token?.accessToken;
        this._accessTokenExpires = token?.accessTokenExpires;
    }

    public async setRefreshTokens(token: IRefreshToken): Promise<boolean> {
        await this._persistentStorage?.persist({
            refreshToken: token.refreshToken,
            refreshTokenExpires: token.refreshTokenExpires
        });
        return true;
    }

    public setAccessTokenExpires(expires: number): void { this._accessTokenExpires = expires; }
    public clearTokens(): void {
        this._accessToken = undefined;
        this._accessTokenExpires = 0;
        this._persistentStorage?.clear();
    }
}