import { IBoat, useBoatsData } from "../../../Sdk";

export const useBoats = () => {
    const data = useBoatsData();

    return {
        store: data.store,
        defaultImageUrl: "/img/eka.jpg",
        boats: data.boats,
        boat: data?.boat,
        onBoatAdd: async (model: IBoat) => {
            return await data.onBoatAdd(model);
        },
        selectBoat: async (id: string) => {
            return await data.selectBoat(id);
        },
        deleteBoat: async (id: string) => {
            return await data.deleteBoat(id);
        },
    }
}
