
import { LoginStateType, useApplicationSettings, useUser, useUserLoggedIn } from '../../../Sdk';
import { LoggedInLayout } from './LoggedInLayout';
import { NotLoggedInLayout } from './NotLoggedInLayout';
import { RouteMapping } from './RouteMapping';


const useLayoutUI = () => {
    const appState = useApplicationSettings();
    const user = useUser();
    const loginState = user?.loginState;
    const loginMessage = user?.loggingInResponse?.message;
    const onTokenSubmit = async (token: string) => {
        user.loginWithToken(token);
    }

    return {
        loginState: loginState,
        loginMessage: loginMessage,
        onTokenSubmit: onTokenSubmit,
        showNotAuthorizedMessage: appState.showNotAuthorizedMessage
    }
}


export const Layout = () => {
    const ui = useLayoutUI();
    const loggedin = useUserLoggedIn();
    return (
        <>
            <LoggedInLayout loggedin={loggedin}>
                <RouteMapping />
            </LoggedInLayout>

            {(!loggedin) && (
                <NotLoggedInLayout
                    loginState={ui.loginState}
                    onTokenSubmit={ui.onTokenSubmit}
                    error={ui.loginMessage}
                />
            )}
        </>
    )
}

