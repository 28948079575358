
// Debugging

import { usePlatform } from "../../Platform/PlatformProvider";

export const useConsoleLog = (always?: boolean) => {
    const p = usePlatform();
    function log(a?: any, b?: any, c?: any, d?: any, e?: any) {
        if (p.isDebug || always) {
            var args = Array.prototype.slice.call(arguments);
            console.log((new Date()).toLocaleString(), args);
        }
    }
    return log;
}
