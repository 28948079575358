import { useSelector, useDispatch } from "react-redux";
import { useUserManagementApi } from "../../IO/api/useUserManagementApi";
import { SimpleUserType } from "../../IO/api/Types";
import { RdxStore, IDataStoreObject } from "../Redux/Types";
import { CHANGE_USERMANAGEMENT_ROLES, CHANGE_USERMANAGEMENT_USERS } from "../Redux/userManagementReducer";

export const useUserManagement = () => {
    const users = useSelector((state: RdxStore) => state.userManagement.users);
    const roles = useSelector((state: RdxStore) => state.userManagement.roles);
    const dispatch = useDispatch();
    const api = useUserManagementApi();

    const loadUsersAndRoles = async () => {
        // roles
        const r = await api.getRoles();
        let cr: IDataStoreObject<string> = {};
        r?.items?.forEach(role => {
            cr[role] = role;
        });

        // users
        const u = await api.getUsers();
        let cu: IDataStoreObject<SimpleUserType> = {};
        u?.items?.forEach(user => {
            const k = user.id ?? "";
            cu[k] = user;
        });

        dispatch({ type: CHANGE_USERMANAGEMENT_ROLES, payload: cr });
        dispatch({ type: CHANGE_USERMANAGEMENT_USERS, payload: cu });
    }

    const loadUsers = async () => {
        // users
        const u = await api.getUsers();
        let cu: IDataStoreObject<SimpleUserType> = {};
        u.items?.forEach(user => {
            const k = user.id ?? "";
            cu[k] = user;
        });

        dispatch({ type: CHANGE_USERMANAGEMENT_USERS, payload: cu });
    }

    const getUser = async (id: string) => {
        const u = await api.getUser(id);
        if (u?.success && !!u && !!u?.item) {
            const k: string | undefined = u.item.id;
            if (k != undefined) {
                let change = { ...(users ?? {}) };
                change[k] = u.item;
                dispatch({ type: CHANGE_USERMANAGEMENT_USERS, payload: change });
            }
        }
        return users?.[id];
    }

    const deleteUser = async (id: string) => {
        const u = await api.deleteUser(id);

        if (u?.success && !!users?.[id]) {
            let change = { ...(users ?? {}) };
            delete change[id];
            dispatch({ type: CHANGE_USERMANAGEMENT_USERS, payload: change });
        }
        return u?.success;
    }

    return {
        // Load
        loadUsersAndRoles: loadUsersAndRoles,
        loadUsers: loadUsers,

        // Data
        users: users,
        roles: roles,

        // Actions
        addUser: api?.addUser,
        getUser: getUser,
        deleteUser: deleteUser,

        // Ops
        createLoginToken: api.createLoginToken,
        getUserRefreshtokens: api.getUserRefreshtokens,
        deleteUserRefreshtoken: api.deleteUserRefreshtoken
    }
}



export interface IRefreshTokenType {
    refreshTokenExpires: number
    userId: string
    platformName: string
    issued: number
    remoteIp: string
    userAgent: string
    id: string
}