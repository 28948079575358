import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CreateLoginTokenType, Helpers, ListContainer, RestCreatedReponse, SimpleUserType, useFetchHandler, useUser, useUserLoggedIn, useUserManagement } from "../../../Sdk";
import { AppRoutes } from "../../config/AppRoutes";
import { DisplayField } from "../Form/FormsElements";
import { AppScreen, CenterSection, PageHeader, PrimaryButton, SecondaryButton, WideContainer } from "../Helpers/Elements";
import { BlockingOverlay, Overlay } from "../Helpers/Overlay";
import { Toolbar } from "../Helpers/Toolbar";
import { WebDialog } from "../Helpers/WebDialog";

export type CreateUserLoginTokenArgs = { id: string }
export const CreateUserLoginToken = () => {
    const nav = useNavigate();

    const params = useParams<CreateUserLoginTokenArgs>();
    const userId = params?.id ?? "";

    const ui = useUserManagement();
    const user = ui.users?.[userId];

    useEffect(() => {
        if (userId) {
            ui.getUser(userId);
        }
    }, [, userId]);

    const [deleting, setDeleting] = useState<boolean>(false);
    const [response, setResponse] = useState<CreateLoginTokenType | undefined>(undefined)

    return (
        <AppScreen screenTitle="Skapa kod">
            <CenterSection>

                <WideContainer>
                    <PageHeader label={"Skapa kod"} />

                    <DisplayField label={"Namn"} value={user?.firstName + " " + user?.lastName} />

                    <div>
                        <DisplayField label={"Kod"} value={response?.token ?? "Ingen skapad ännu"} />
                    </div>


                    <PrimaryButton label={"Skapa"}
                        onClick={async () => {
                            const r = await ui.createLoginToken(userId);
                            setResponse(r);
                        }}
                        marginTop="100px" />


                    <SecondaryButton label={"Tillbaka"} onClick={() => nav(-1)} marginTop="20px" />
                </WideContainer>

            </CenterSection>
            <BlockingOverlay visible={deleting} />
        </AppScreen>
    )
}
