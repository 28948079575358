export const InputHelpers = {
    textInput: (value: string, onChange: (e: string) => void, onEnter?: () => void) => {
        return {
            value: value,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value)
            },
            onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key == "Enter" && !!onEnter) {
                    onEnter();
                }
            },
        };
    }
}