import { Helpers } from "../../../Sdk";
import { useBoats } from "../Boat/useBoats";
import { AppScreen, CenterSection, Padder, PageHeader, Scrollable, WideContainer } from "../Helpers/Elements"

export const Events = () => {
    const ui = useEvents();

    return (
        <AppScreen className="events" screenTitle="Händelser" removePadding={true}>
            <CenterSection>
                <Scrollable head={(
                    <WideContainer>
                        <PageHeader label={"Händelser"} />
                    </WideContainer>
                )}>
                    <div>
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => (
                            <EventElement key={i} />
                        ))}
                    </div>
                    <Padder />
                </Scrollable>
            </CenterSection>
        </AppScreen>
    )
}


const EventElement = () => {
    const boats = useBoats();
    const boat = boats.store?.[boats?.boats?.[0].id ?? ""];
    const date = Helpers.epochToFormattedDate(1626378064);


    return (
        <WideContainer>
            <div className="event">
                <div>
                    <div className="ico">
                        <img src={boat?.image} loading="lazy" />
                    </div>
                </div>
                <div className="texts">
                    <div className="title">
                        {boat?.name}&nbsp;
                        <span className="timestamp">
                            {boat?.model}&nbsp;{boat?.manufacturer}
                        </span>
                    </div>
                    <div className="timestamp">
                        {date}
                    </div>
                    <div className="description">
                        ald jasl djsldj adlkjkl
                        ald jasl djsldj adlkjkl
                        ald jasl djsldj adlkjkl
                        ald jasl djsldj adlkjkl
                        ald jasl djsldj adlkjkl
                        ald jasl djsldj adlkjkl
                    </div>

                </div>
            </div>
        </WideContainer>
    )
}

const useEvents = () => {


    return {

    }
}