import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helpers, ListContainer, RestCreatedReponse, SimpleUserType, useFetchHandler, useUser, useUserLoggedIn, useUserManagement } from "../../../Sdk";
import { AppRoutes } from "../../config/AppRoutes";
import { AppScreen, Padder, PageHeader, Scrollable, SecondaryButton, WideContainer } from "../Helpers/Elements";
import { BIcons } from "../Helpers/Icons";
import { Toolbar } from "../Helpers/Toolbar";
import { WebDialog } from "../Helpers/WebDialog";

export const Users = () => {
    const ui = useUserManagement();
    const user = useUser();
    const nav = useNavigate();
    const loggedIn = useUserLoggedIn();

    useEffect(() => {
        if (loggedIn && user.hasRole("USERADMIN")) {
            ui.loadUsersAndRoles();
        }
        console.log("loggedIn:", loggedIn);
    }, [loggedIn]);

    return (
        <AppScreen screenTitle="Användare" removePadding={true}>
            <Scrollable head={(
                <WideContainer>
                    <PageHeader label={"Användare"} />
                    <Toolbar items={[
                        { id: "1", label: "Lägg till användare", onPress: () => nav(AppRoutes.adduser), icon: BIcons.plus },

                    ]} />
                </WideContainer>
            )}>
                <WideContainer>
                    <div className="card-container">
                        {!!ui.users && Object.keys(ui.users ?? {})?.map(uid => {
                            const u = ui.users?.[uid] ?? {};
                            return (
                                <UserCard key={uid} user={u} />
                            )
                        })}
                    </div>
                    <Padder />
                </WideContainer>
            </Scrollable>
        </AppScreen>
    )
}


const UserCard = ({ user }: { user: SimpleUserType }) => {

    const nav = useNavigate();
    return (
        <div className="card data-card" onClick={() => { nav(AppRoutes.getUserDetailView(user?.id)) }}>
            <div>
                <div className="card-image" />
            </div>
            <div>
                <div className="title">{user?.email}</div>
                <div className="title">{user?.roles?.join(", ")}</div>
                <div className="title">{user?.uiLocale}</div>
            </div>

        </div>
    )
}



