import { IConfig } from "../../Sdk";
import packageJson from "../../../package.json"

const apiServerProd = process.env.REACT_APP_API_SERVER_PROD;

const apiServerStage = process.env.REACT_APP_API_SERVER_STAGE;
const apiServerDev = process.env.REACT_APP_API_SERVER_DEV;
const buildHash = process.env.REACT_APP_COMMIT_HASH ?? "N/A";
const buildBranch = process.env.REACT_APP_COMMIT_BRANCH ?? "nobranch";
const buildTime: number = parseInt(process.env.REACT_APP_COMMIT_TIME ?? "") ?? 0;

const isDebug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const Config: IConfig = {
    appName: process.env.REACT_APP_NAME ?? "N/A",
    apiServer: (isDebug ? apiServerDev : apiServerStage) ?? "",
    appVersion: process.env.REACT_APP_VERSION ?? "N/A",

    // Build
    buildHash: buildHash ?? "-",
    buildBranch: buildBranch ?? "-",
    buildTime: buildTime,

    consoleLogRequests: false,
    consoleLogPostInfo: false,
}