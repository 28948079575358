import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SimpleUserType, useUser, useUserLoggedIn, useUserManagement } from "../../../Sdk";
import { EditFieldText } from "../Form/FormsElements";
import { AppScreen, CenterSection, PageHeader, PrimaryButton, SecondaryButton, WideContainer } from "../Helpers/Elements"

export const AddUser = () => {

    const ui = useUserManagement();
    const [model, setModel] = useState<SimpleUserType>({});
    const user = useUser();
    const loggedIn = useUserLoggedIn();
    const nav = useNavigate();

    const onSave = () => {
        ui.addUser(model?.email ?? "", model?.firstName ?? "", model?.lastName ?? "").then(d => {
            if (d.success) {
                setModel({});
                ui.loadUsersAndRoles();
                nav(-1);
            } else {
                alert(d?.message);
            }
        })
    }

    const onCancel = () => {
        nav(-1);
    }

    return (
        <AppScreen screenTitle="Lägg till användare">
            <CenterSection>
                <WideContainer>
                    <PageHeader label={"Lägg till användare"} />

                    <EditFieldText label={"Email"} onChange={e => setModel({ ...model, email: e })} />
                    <EditFieldText label={"FirstName"} onChange={e => setModel({ ...model, firstName: e })} />
                    <EditFieldText label={"LastName"} onChange={e => setModel({ ...model, lastName: e })} />

                    <PrimaryButton label={"Spara"} onClick={onSave} marginTop="20px" />
                    <SecondaryButton label={"Ångra"} onClick={onCancel} marginTop="20px" />
                </WideContainer>
            </CenterSection>
        </AppScreen>
    )
}