import { useNavigate } from "react-router-dom";
import { useUser } from "../../../Sdk";
import { DisplayField, DisplayFieldEditable, EditFieldText, FormSection } from "../Form/FormsElements";
import { AppScreen, CenterSection, PageHeader, SecondaryButton, WideContainer } from "../Helpers/Elements";

export const MyProfile = () => {
    const nav = useNavigate();
    const me = useUser();



    return (
        <AppScreen screenTitle="Profil">
            <CenterSection>
                <WideContainer>
                    <PageHeader label="Min profil" />
                    <FormSection>
                        <DisplayField label={"Namn"} value={me?.fullName ?? "Inget namn"} />
                        <DisplayField label={"Email"} value={me?.email ?? "Inget namn"} />
                        <DisplayField label={"Tel"} value={me?.phoneNumber ?? "Inget nummer"} />
                        <DisplayField label={"Roller"} value={me?.roles?.join(",") ?? "Inga roller"} />
                        <DisplayField label={"UiLocale"} value={me?.uiLocale} />
                    </FormSection>
                </WideContainer>
            </CenterSection>
        </AppScreen>
    )
}