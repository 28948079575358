import { useSelector, useDispatch } from "react-redux"
import { CHANGE_APPSTATE, CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE } from "../Redux/appReducer";
import { RdxStore } from "../Redux/Types"

export const useApplicationSettings = () => {
    const dispatch = useDispatch();
    const showNotAuthorizedMessage = useSelector((state: RdxStore) => state.app.showNotAuthorizedMessage);
    const appState = useSelector((state: RdxStore) => state.app.appState);

    return {
        // NotAuthorizedMessage
        showNotAuthorizedMessage: showNotAuthorizedMessage,
        dismissNotAuthorizedMessage: () => {
            dispatch({ type: CHANGE_SHOW_NOT_AUTHORIZED_MESSAGE, payload: false })
        },
        appState: appState,
    }

}