import { ReactNode, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { IBoat, TokenStore, usePlatform, useTicker, useUser } from "../../../Sdk"
import { AppRoutes } from "../../config/AppRoutes";
import { usePageTitle } from "../Hooks/usePageTitle";
import { BIcon, BIcons } from "./Icons";
import { PortalContainer } from "./Portal";

export const AccessExpires = () => {

    const [accessTokenExpires, setAccessTokenExpires] = useState<number>(0);

    const tick1second = useTicker(1);
    const tick60seconds = useTicker(60);

    const [onoroff, setOnoroff] = useState<boolean>(false);
    useEffect(() => {
        setOnoroff(!onoroff);
    }, [, tick60seconds])



    useEffect(() => {
        TokenStore.getInstance().getTokens().then(e => {
            setAccessTokenExpires(e.accessTokenExpires ?? 0)
        });
    }, [, tick1second]);


    return (
        <span style={{ fontStyle: onoroff ? "italic" : "normal" }}>
            {accessTokenExpires - Math.round((new Date()).valueOf() / 1000)} s
        </span>
    )

}



export const AppScreen = ({ children, className, screenTitle, removePadding }: { children?: ReactNode, className?: string, screenTitle: string, removePadding?: boolean }) => {
    const title = usePageTitle(screenTitle);
    return (
        <div className={className}>
            {children}
            {!removePadding && (
                <Padder />
            )}
        </div>
    )
}

export const Padder = ({ size = "50px" }: { size?: string }) => {
    return (
        <div style={{ height: size }}></div>
    )
}



export const WideContainer = ({ children, marginTop = "0px" }: { children: ReactNode, marginTop?: string }) => {
    return (
        <div className="wide-container" style={{ marginTop: marginTop }}>
            {children}
        </div>
    )
}


export type MenuItemType = {
    id: string,
    label: string,
    onClick(): void
}
export const PageHeader = ({ label, menuItems }: { label: string, menuItems?: MenuItemType[] }) => {

    const platform = usePlatform();
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const hide = () => { setShowMenu(false); }
    const show = () => { setShowMenu(true); }
    const user = useUser();
    const nav = useNavigate();

    return (
        <div className="page-header">
            <h2 className="header">
                {label}
            </h2>
            {!!menuItems && (
                <div className="menu"
                    //onClick={menuClick}
                    onClick={show}
                >
                    <BIcon icon={BIcons.menu} size={"lg"} />
                </div>
            )}


            <PortalContainer portalId="portal-bottommenu" visible={showMenu} onOutSideClick={hide}>
                <div className={"bottom-menu-panel" + ((menuItems?.length ?? 0) > 0 ? " bottom-menu-panel-items-" + (50 + 50 * (menuItems?.length ?? 0)) + "px" : "")}>
                    <div className="flex0">
                        <ul onClick={hide}>
                            {menuItems?.map(item => (
                                <li key={item?.id} onClick={item?.onClick}>
                                    {item?.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Padder />
                </div>
            </PortalContainer>
        </div>
    )
}


export const PrimaryButton = ({ label, onClick, marginTop, disabled, ref }: { label: string, onClick(): void, marginTop?: string, disabled?: boolean, ref?: React.RefObject<HTMLButtonElement> }) => {

    return (
        <div className="btn-container">
            <button ref={ref} className="btn btn-primary" onClick={onClick} style={{ marginTop: marginTop }} disabled={disabled}>
                {label}
            </button>
        </div>
    )

}
export const SecondaryButton = ({ label, onClick, marginTop, disabled }: { label: string, onClick(): void, marginTop?: string, disabled?: boolean }) => {

    return (
        <div className="btn-container">
            <button className="btn-secondary" onClick={onClick} style={{ marginTop: marginTop }} disabled={disabled}>
                {label}
            </button>
        </div>
    )

}

export const BoatNameGeneric = ({ boat }: { boat: IBoat | undefined }) => {
    const name = boat?.name ?? "";
    const type = ((boat?.manufacturer ?? "") + " " + (boat?.model ?? ""))?.trim();

    return (

        <span>
            {name}
            {!!type && (
                <span style={{ fontSize: "0.7rem" }}> - {type}</span>
            )}
        </span>

    )
}


export const CardContainer = ({ children, className }: { children: ReactNode, className?: string }) => {
    return (
        <div className={"card-container" + (!!className ? " " + className : "")}>
            {children}
        </div>
    )
}

export const CardElement = ({ children, className, onClick }: { children: ReactNode, className?: string, onClick?(): void }) => {
    return (
        <div className={"card" + (!!className ? " " + className : "")} onClick={onClick}>
            {children}
        </div>
    )
}


export const CenterSection = ({ children, className }: { children?: ReactNode, className?: string }) => {
    return (
        <div className={"section-center" + (!!className ? " " + className : "")}>
            {children}
        </div>
    )
}


export const Scrollable = ({ head, children }: { head: ReactNode, children: ReactNode, }) => {
    return (
        <div className="flex-container-with-header">
            <div className="flex0">
                {head}
            </div>
            <div className="flex1 overflow-scroll">
                {children}
            </div>

        </div>
    )
}