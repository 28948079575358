import { ReactNode, useState } from "react"
import { BIcon, BIcons } from "../Helpers/Icons"

export const FormSection = ({ children }: { children: ReactNode }) => {
    return (
        <div className="frm-section">
            {children}
        </div>
    )
}


export const FormInput = ({ defaultValue }: { defaultValue: string }) => {
    return (
        <input type={"text"} defaultValue={defaultValue} />
    )
}


export const DisplayField = ({ label, value }: { label: string, value: string | undefined }) => {
    return (
        <div className="frm">
            <label>{label}</label>
            <div className="data">
                {value}
            </div>
        </div>
    )
}
export const DisplayImage = ({ url }: { url: string | undefined }) => {
    return (
        <div className="frm-image">
            <img className="image" src={url} loading="lazy" />
        </div>
    )
}

export const EditFieldText = (
    { label, defaultValue = "", onChange, autoFocus }:
        { label: string, defaultValue?: string | undefined, onChange(e: string): void, autoFocus?: boolean }) => {
    return (
        <div className="frm">
            <label>{label}</label>
            <input
                type={"text"}
                defaultValue={defaultValue}
                onChange={e => onChange(e.target.value)}
                autoComplete="off"
                autoFocus={autoFocus}
            />
        </div>
    )
}
export const EditFieldTextArea = (
    { label, defaultValue = "", onChange, minHeight = "200px" }:
        { label: string, defaultValue?: string | undefined, onChange(e: string): void, minHeight?: string }) => {
    return (
        <div className="frm">
            <label>{label}</label>
            <textarea
                style={{ minHeight: minHeight }}
                defaultValue={defaultValue}
                onChange={e => onChange(e.target.value)}
                autoComplete="off"
                spellCheck={false}
            ></textarea>
        </div>
    )
}


export const DisplayFieldEditable = ({ label, value }: { label: string, value: string | undefined }) => {
    type TState = "display" | "edit";
    const [viewState, setViewState] = useState<TState>("display")
    const onChange = (e: string) => { }
    return (
        <div className="frm frm-editable">
            <div className="content">
                <label>{label}</label>
                {viewState === "display" && (
                    <div className="data">
                        {value}
                    </div>
                )}
                {viewState === "edit" && (
                    <input
                        type={"text"}
                        defaultValue={value}
                        onChange={e => onChange(e.target.value)}
                        autoComplete="off"
                    />
                )}
            </div>
            <div className="edit-ico" onClick={() => setViewState(viewState === "display" ? "edit" : "display")}>
                <BIcon icon={BIcons.edit} size="lg" />
            </div>
        </div>
    )
}