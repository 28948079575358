
export type IconSize = "xs" | "sm" | "lg" | "2x" | "3x" | "5x" | "7x" | "10x";
export const BIcon = ({ icon, title, className, size }: { icon: string, title?: string, className?: string, size?: IconSize }) => {
    const sizeClass = size ? " fa-" + size : "";
    const classNameClass = className ? " " + className : "";
    const iconClass = " " + icon;
    return (
        <>
            <i className={"icon faicon " + iconClass + classNameClass + sizeClass} title={title}></i>
        </>
    )
}

// Get Icons from https://fontawesome.com/v5/search?m=free
export const BIcons = {
    edit: "far fa-edit",
    plus: "fas fa-plus",
    bell: "fas fa-bell",
    menu: "fas fa-bars",
    camera: "fas fa-camera",
    images: "fas fa-images",
    chevron_right: "fas fa-chevron-right",
    chevron_left: "fas fa-chevron-left",
    chevron_up: "fas fa-chevron-up",
    chevron_dow: "fas fa-chevron-down",

    file: "fas fa-file-alt",
    profile: "fas fa-user-circle",
    pencil: "fas fa-pencil-alt",

    times: "fas fa-times",
    ship: "fas fa-ship",
    spinner: "fas fa-spinner fa-spin",
    upload: "fas fa-upload",
    users: "fas fa-users",
    cog: "fas fa-cog",
}