import React, { Children, createContext, ReactNode, useContext, useState } from 'react';
import { IPlatform } from '..';

// Context
export const PlatformContext = createContext<IPlatform | undefined>(undefined);

// Provider
export const PlatformProvider = ({ platform, children }: { platform: IPlatform, children: ReactNode }) => {
    return (
        <PlatformContext.Provider value={platform}>
            {children}
        </PlatformContext.Provider>
    )
}

// Hook
export const usePlatform = () => {
    const platform = useContext(PlatformContext);
    if (!platform) {
        throw new Error("No platform avalaible!")
    }
    return platform;
}