import { DateTime } from "luxon";
import Cookies from "universal-cookie";
import { IRefreshToken, ITokenPersistentStorage } from "../../Sdk";

type LocalCookieObject = {
    exp?: number,
    tk?: string
}

export const tokenPersistentStorage = (): ITokenPersistentStorage => {
    const refreshTokenCookieName = "refr";
    const cookies = new Cookies();


    // Transforms
    const localToToken = (obj: LocalCookieObject): IRefreshToken => {
        return { refreshToken: obj?.tk, refreshTokenExpires: obj?.exp }
    }
    const TokenToLocal = (obj: IRefreshToken): LocalCookieObject => {
        return { tk: obj?.refreshToken, exp: obj?.refreshTokenExpires }
    }


    // persistentStorage
    const persistentStorage: ITokenPersistentStorage = {

        // ----------------------- persist -----------------------
        persist: async (token: IRefreshToken): Promise<boolean> => {
            if (!!token) {

                const str = JSON.stringify(TokenToLocal(token));
                const expires = DateTime.now().plus({ days: 365 }).toJSDate();
                const path = "/";

                cookies.set(refreshTokenCookieName, str, { expires: expires, path: path });
            }
            return true;
        },
        // ----------------------- read -----------------------
        read: async (): Promise<IRefreshToken> => {
            const str = cookies.get(refreshTokenCookieName);
            if (str) {
                return localToToken(str as LocalCookieObject);
            }
            return localToToken("" as LocalCookieObject);
        },
        // ----------------------- clear -----------------------
        clear: async (): Promise<boolean> => {
            cookies.remove(refreshTokenCookieName);
            console.log("clear")
            return true;
        }
    };

    // return { persistentStorage: persistentStorage,}
    return persistentStorage
}