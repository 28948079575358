import { useEffect, useState } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Helpers, IBoat } from "../../../Sdk";
import { AppRoutes } from "../../config/AppRoutes";
import { AppScreen, BoatNameGeneric, CenterSection, Padder, PageHeader, WideContainer } from "../Helpers/Elements";
import { BIcons } from "../Helpers/Icons";
import { Toolbar } from "../Helpers/Toolbar";
import { useBoats } from "./useBoats";

export type BoatDetailsArgs = { id: string }
export const BoatDetailsDocuments = () => {

    const params = useParams<BoatDetailsArgs>();
    const boatId = params?.id as string;
    const boat = useBoats();
    const selected = boat?.boat;
    const [model, setModel] = useState<IBoat>({});


    const nav = useNavigate();

    useEffect(() => {
        if (boatId) {
            boat.selectBoat(boatId);
        }
    }, []);

    useEffect(() => {
        setModel(selected ?? {})
    }, [selected]);


    const onEdit = () => {
        nav(AppRoutes.getBoatdetailEdit(boatId));
    }


    return (
        <AppScreen screenTitle={"Dokument för " + model?.name}>
            <CenterSection>
                <WideContainer>

                    <PageHeader label={"Dokument för " + model?.name}
                        menuItems={[
                            // { id: "edit", label: "Redigera", onClick: onEdit },
                            // { id: "docs", label: "Dokument", onClick: () => { alert("Visa alla dokument") } },
                            { id: "service", label: "Händelser", onClick: () => { alert("Visa alla händelser, service osv.") } },
                            { id: "goback", label: "Tillbaka", onClick: () => { nav(-1) } },
                        ]}
                    />
                    <Toolbar items={[
                        { id: "goback", label: "Tillbaka", onPress: () => { nav(-1) }, icon: BIcons.chevron_left },
                        { id: "adddoc", label: "Lägg till dokument", onPress: () => nav(AppRoutes.getUploadFilesToObject("boats", model?.id ?? "")), icon: BIcons.plus },
                    ]} />

                    <Padder size="10px" />

                    <div className="frm">
                        <div className="data">
                            <BoatNameGeneric boat={model} />
                        </div>
                    </div>
                    <Padder size="20px" />
                    <table className="tbl tbl-small">
                        <thead>
                            <tr>
                                <th>Namn</th>
                                <th>Datum</th>
                                <th>Publik</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(model?.boatDocuments ?? {})?.map(key => (
                                <tr key={key}>
                                    <td>
                                        {model?.boatDocuments?.[key]?.name}
                                    </td>
                                    <td>
                                        {Helpers.epochToFormattedDate(model?.boatDocuments?.[key]?.createdEpoch)}
                                    </td>
                                    <td>
                                        {model?.boatDocuments?.[key]?.public && "Öppen"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </WideContainer>
            </CenterSection>
        </AppScreen>
    )

}