import { BIcon } from "./Icons"

export type ToolbarItem = {
    id: string,
    label: string,
    onPress(): void,
    icon?: string,
    disabled?: boolean
}
export const Toolbar = ({ items }: { items: ToolbarItem[] }) => {
    return (
        <div className="toolbar-container">
            {items?.map(i => (
                <ToolbarItemElement key={i?.id} item={i} />
            ))}
        </div>
    )
}

const ToolbarItemElement = ({ item }: { item: ToolbarItem }) => {

    return (
        <div className={"toolbar-item" + (item?.disabled ? " disabled" : "")} onClick={item?.disabled ? () => { } : item?.onPress}>
            {!!item?.icon && (
                <BIcon icon={item?.icon} />
            )}
            {!!item?.label && (
                <span className="text">
                    {item?.label}
                </span>
            )}
        </div>
    )
}