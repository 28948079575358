
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useUserApi } from "./useUserApi";
import { RdxStore } from "../Data/Redux/Types";
import { CHANGE_LOGIN_TOKEN, CHANGE_LOGGING_IN, UserType, CHANGE_USER_STATE, CHANGE_COMMAND_RELOAD_USERDATA, RESET_USER_REDUCER, LoggingInResponseType, CHANGE_LOGGING_IN_RESPONSE } from "../Data/Redux/userReducer";
import { TokenStore } from "../Platform/TokenStore";
import { useConsoleLog } from "../Data/hooks/useConsoleLog";
import { useRunOnSchedule, useTicker } from "../Misc/Hooks";
import { useFetchHandler } from "../IO/api/useFetchHandler";

// ########################################################################
// ######################### useUserDataProvider ##########################
// ########################################################################
// Placed in App.tsx. Only one instance per application

export const useUserDataProvider = () => {
    const user = useSelector((store: RdxStore) => store.user.user);
    const commandReloadUserData = useSelector((store: RdxStore) => store.user.commandReloadUserData);
    const dispatch = useDispatch();
    const userApi = useUserApi();
    const log = useConsoleLog();

    // ######################## Start ########################
    const onStart = async () => {
        const hasAccessToken = !!(await TokenStore?.getInstance().getTokens())?.accessToken;
        const hasRefreshToken = !!(await TokenStore?.getInstance().getTokens())?.refreshToken;
        if ((hasAccessToken || hasRefreshToken) && !user?.id) {
            await loadUserData();
        }
    }

    useEffect(() => {
        onStart();
    }, []);


    const loadUserData = async () => {
        // dont load if jwt token isnt present
        const tokens = await TokenStore?.getInstance().getTokens();
        // console.log("loadUserData: ", tokens);
        const hasAccessToken = !!tokens?.accessToken;
        const hasRefreshToken = !!tokens?.refreshToken;
        if (hasAccessToken === false && hasRefreshToken === false) { return; }

        // dont load if user already loaded
        if (!!user?.id) { return; }

        await reloadUserData();

        dispatch({ type: CHANGE_LOGIN_TOKEN, payload: null });
    }

    const reloadUserData = async () => {
        log("reloadUserData...")
        if (!user?.id) {
            dispatch({ type: CHANGE_LOGGING_IN, payload: true });
        }
        const u = await userApi.checkAuth();
        let userData: UserType = { ...u };
        if (u.loggedin) {
            userData.name = "N/A";
        }
        dispatch({ type: CHANGE_LOGGING_IN, payload: false });
        dispatch({ type: CHANGE_USER_STATE, payload: userData });
        dispatch({ type: CHANGE_COMMAND_RELOAD_USERDATA, payload: false });
    }

    useEffect(() => {
        if (commandReloadUserData) {
            reloadUserData();
        }
    }, [commandReloadUserData])


    // ######################## Logout ########################
    const logOutClicked = useSelector((store: RdxStore) => store.user.commandLogOut);
    const logout = async () => {
        log("logout...")

        const tokens = await TokenStore?.getInstance().getTokens();
        await userApi.logout(tokens?.refreshToken ?? "");
        TokenStore.getInstance().clearTokens();
        dispatch({ type: RESET_USER_REDUCER });
        // dispatch({ type: RESET_APP_REDUCER });
    }
    useEffect(() => {
        if (logOutClicked) {
            logout();
        }
    }, [logOutClicked])



    // ############## LoginToken passed to redux ########################
    const loginToken = useSelector((store: RdxStore) => store.user.loginToken);
    useEffect(() => {
        if (loginToken) {
            login(loginToken);
        }
    }, [loginToken]);

    const login = async (token: string): Promise<boolean> => {
        dispatch({ type: CHANGE_LOGGING_IN, payload: true });
        const response = await userApi.postToken(token);
        if (response?.success) {
            const dto: LoggingInResponseType = { success: true, message: undefined };
            dispatch({ type: CHANGE_LOGGING_IN_RESPONSE, payload: dto });

            TokenStore.getInstance().setTokens(response);
            await loadUserData();
        } else {
            const dto: LoggingInResponseType = { success: false, message: response?.message };
            dispatch({ type: CHANGE_LOGGING_IN_RESPONSE, payload: dto });
        }
        dispatch({ type: CHANGE_LOGGING_IN, payload: false });
        return response.success ?? false;
    }

    // ############## Ping to keep session alive ########################
    // const [pingCounter, setPingCounter] = useState<number>(0)
    // useEffect(() => {
    //     setInterval(() => {
    //         userApi.ping().then(response => {
    //             console.log("ping: ", response?.message, pingCounter);
    //             setPingCounter(pingCounter + 1);
    //         })
    //     }, 20 * 60 * 1000);
    // }, [])

    return {
        ...user,
        logOutClicked: logOutClicked,
        loginToken: loginToken,
    }

}


// ########################################################################
// ##################### useKeepRefreshTokenUpdated #######################
// ########################################################################
// Placed in App.tsx. Only one instance per application
// 
export const useKeepRefreshTokenUpdated = () => {
    const api = useUserApi();
    const log = useConsoleLog();

    const oneHourTicker = useTicker(3600);
    const [started] = useState(Math.round((new Date()).valueOf() / 1000));


    const rotate = async () => {

        // Not first 10 seconds.
        const now = Math.round((new Date()).valueOf() / 1000);
        if (now < (started + 10)) { return; }


        log("rotate!")
        // Get current AccessToken
        const tokens = await TokenStore?.getInstance().getTokens();
        const oldRefreshToken = tokens?.refreshToken;

        log("rotate, oldRefreshToken:", oldRefreshToken);
        // Missing Refresh Token => return
        if (!oldRefreshToken) {
            return;
        }

        // Rotate refreshtoken
        const data = await api.rotateRefreshtoken(oldRefreshToken);
        if (!!data?.refreshToken && !!data?.refreshTokenExpires) {
            log("rotate, has token!")

            // Save new refreshtoken
            const saved = await TokenStore?.getInstance().setRefreshTokens({
                refreshToken: data?.refreshToken,
                refreshTokenExpires: data?.refreshTokenExpires
            });

            // Expire old refreshtoken
            if (saved) {
                // log("rotate, new token is saved!")
                const expire = await api.expireRefreshToken(oldRefreshToken);
                // if (!expire) {
                //     log("could not expire accesstoken: ", oldRefreshToken)
                // } else {
                //     log("accesstoken expired: ", oldRefreshToken, expire)
                // }
            }
        }
    }

    // rotate every hour.
    useEffect(() => {
        rotate();
    }, [oneHourTicker]);

    useEffect(() => {
        // First after 30 seconds
        const t_timer = setTimeout(() => { rotate(); }, 30 * 1000);
        return () => {
            clearTimeout(t_timer);
        };
    }, []);

    return oneHourTicker;
}

export const useKeepAccessTokenUpdated = () => {
    const f = useFetchHandler();
    const scheduler = useRunOnSchedule(14 * 60, () => {
        TokenStore.getInstance().getTokens().then(e => {
            f.updateAccessToken();
        });
    }, false);
    return scheduler;
}