import { TUploadFileToObjectType } from "../ui/Files/UploadFiles";

export const AppRoutes = {
    // -------------------------------------------------
    home: "/",

    // --------------------- Users ---------------------
    users: "/users",
    // Add
    adduser: "/users/adduser",
    // View
    userdetailview: "/users/:id",
    getUserDetailView: (id: string | undefined) => "/users/" + id,
    // Create token
    createuserlogintoken: "/users/:id/createuserlogintoken",
    getCreateUserLoginTokenView: (id: string | undefined) => "/users/" + id + "/createuserlogintoken",

    usertokens: "/users/tokens",

    // --------------------- Boat ---------------------
    boats: "/boats",
    addboat: "/boats/addboat",
    // View
    boatdetailview: "/boats/:id/view",
    getBoatdetailView: (id: string | undefined) => "/boats/" + id + "/view",
    // Edit
    boatdetailedit: "/boats/:id/edit",
    getBoatdetailEdit: (id: string | undefined) => "/boats/" + id + "/edit",
    // Docs
    boatdetaildocuments: "/boats/:id/docs",
    getBoatdetaildDcuments: (id: string | undefined) => "/boats/" + id + "/docs",

    // --------------------- Mix ---------------------

    uploadfilestoobject: "/:type/:parentid/uploadfiles",
    getUploadFilesToObject: (type: TUploadFileToObjectType, parentid: string) => "/" + type + "/" + parentid + "uploadfiles/",
    uploadimage: "/:type/:parentid/uploadimage",
    getUploadImageToObject: (type: TUploadFileToObjectType, parentid: string) => "/" + type + "/" + parentid + "/uploadimage/",

    // --------------------- Events  ---------------------
    events: "/events",
    eventdetail: "/events/:id",
    getEventdetail: (id: string) => "/events/" + id,
    // --------------------- Settings  ---------------------
    settings: "/settings",
    uploadfiles: "/settings/uploadfiles",
    myprofile: "/settings/myprofile",
    files: "/settings/files",

}