import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "../../config/AppRoutes"
import { AppScreen } from "../Helpers/Elements"
import { BIcons, BIcon } from "../Helpers/Icons"

type TBottomMenuItem = {
    id: string
    label: string
    route: string
    icon: string
}


export const useBottomMenu = () => {
    const bottomMenuItems: TBottomMenuItem[] = [
        { id: "bm-home", label: "Båtar", route: AppRoutes.boats, icon: BIcons.ship },
        { id: "bm-users", label: "Användare", route: AppRoutes.users, icon: BIcons.users },
        { id: "bm-events", label: "Händelser", route: AppRoutes.events, icon: BIcons.bell },
        { id: "bm-settings", label: "inställningar", route: AppRoutes.settings, icon: BIcons.cog },
    ]

    return {
        bottomMenuItems: bottomMenuItems,
    }

}


export const BottomMenu = () => {
    const ui = useBottomMenu();
    const nav = useNavigate()
    const [lastClicked, setLastClicked] = useState<string>(ui.bottomMenuItems[0].route);

    const loc = useLocation();
    const pathname = loc.pathname;

    useEffect(() => {
        console.log("pathname:", pathname)
    }, [pathname])


    useEffect(() => {
        if (pathname == AppRoutes.home) {
            nav(AppRoutes.boats);
        }
    }, [])


    return (
        <div id='ui-bottom-menu-ui'>
            {ui.bottomMenuItems.map(item => (
                <BottomMenuItem key={item.id} {...item} onClick={() => {
                    nav(item?.route);
                }} active={pathname.startsWith(item.route)} />
            ))}
        </div>
    )

}


const BottomMenuItem = ({ label, onClick, icon, id, active }: { label: string, onClick(): void, icon: string, id: string, active: boolean }) => {

    return (
        <div className={active ? 'menuitem active' : 'menuitem'} onClick={onClick} data-testid={id}>
            <div className='icon'>
                <BIcon icon={icon} size="lg" />
            </div>
            <div className='label'>
                {label}
            </div>
        </div>
    )

}