import React, { ReactNode, useEffect, useState } from 'react';
import { Routes, Route, useLocation, useSearchParams, useNavigate, useNavigation } from 'react-router-dom';
import { AppRoutes } from '../../config/AppRoutes';
import { AddBoat } from '../Boat/AddBoat';
import { BoatDetails } from '../Boat/BoatDetails';
import { BoatDetailsDocuments } from '../Boat/BoatDetailsDocuments';
import { BoatDetailsView } from '../Boat/BoatDetailsView';
import { UploadFiles } from '../Files/UploadFiles';
import { Home } from '../Home/Home';
import { Settings } from '../Settings/Settings';
import { MyProfile } from '../Profile/MyProfile';
import { AddUser } from '../Users/AddUser';
import { CreateUserLoginToken } from '../Users/CreateUserLoginToken';
import { UserDetailView } from '../Users/UserDetailView';
import { Users } from '../Users/Users';
import { Events } from '../Settings/Events';
import { UploadImage } from '../Files/UploadImage';

export const RouteMapping = () => {
    return (
        <Routes>
            <Route path={AppRoutes.home} element={<Home />} />
            <Route path={AppRoutes.settings} element={<Settings />} />
            <Route path={AppRoutes.events} element={<Events />} />


            <Route path={AppRoutes.users} element={<Users />} />
            <Route path={AppRoutes.userdetailview} element={<UserDetailView />} />
            <Route path={AppRoutes.adduser} element={<AddUser />} />
            <Route path={AppRoutes.createuserlogintoken} element={<CreateUserLoginToken />} />

            <Route path={AppRoutes.myprofile} element={<MyProfile />} />

            <Route path={AppRoutes.uploadfiles} element={<UploadFiles />} />
            <Route path={AppRoutes.uploadfilestoobject} element={<UploadFiles />} />
            <Route path={AppRoutes.uploadimage} element={<UploadImage />} />


            <Route path={AppRoutes.boats} element={<Home />} />
            <Route path={AppRoutes.addboat} element={<AddBoat />} />
            <Route path={AppRoutes.boatdetailview} element={<BoatDetailsView />} />
            <Route path={AppRoutes.boatdetailedit} element={<BoatDetails />} />
            <Route path={AppRoutes.boatdetaildocuments} element={<BoatDetailsDocuments />} />
        </Routes>
    )
}