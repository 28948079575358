import { IPlatform, /*IRefreshToken, ITokenPersistentStorage,*/ IUnsecureStorage, unsecureStorageKeys } from "../../Sdk";
import { Config } from "../config/Config";
import { MessageBoxes } from "./MessageBoxes";

export type KeyboardKey = "Enter" | "Tab";

export const CurrentPlatformImplementations = (): IPlatform => {
    return {
        platformName: "web",
        uiHandler: {
            MessageBoxes: MessageBoxes
        },
        unsecureStorage: unsecureStorage,
        apiDomain: Config.apiServer,
        Config: Config,
        isDebug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    }
}



const unsecureStorage: IUnsecureStorage = {
    get: async (key: unsecureStorageKeys): Promise<any> => {
        const str = localStorage.getItem(key);
        return JSON.parse(str ?? "");
    },
    set: async (key: unsecureStorageKeys, value: any): Promise<boolean> => {
        localStorage.setItem(key, JSON.stringify(value));
        return false;
    }
}
