import { useCallback, useEffect } from "react";

export const useKeyboardShortcut = (callback: () => Promise<void>, keyCodes: string[], dependencies?: any): void => {
    const handler = (e: KeyboardEvent) => {
        // const notOverridable = ["r", "c", "v", "z", "ArrowUp", "ArrowRight", "ArrowDown", "ArrowLeft", "Control", "Shift", "End", "Home"];
        // if (e.ctrlKey && notOverridable.indexOf(e.key) < 0) {
        //     console.log("override: ", e.key);
        //     e.preventDefault();
        // }

        if (e.ctrlKey && keyCodes.includes(e.key)) {
            e.preventDefault();
            callback();
        }
        return;
    };

    useEffect(() => {
        window.addEventListener("keydown", handler);
        return () => {
            window.removeEventListener("keydown", handler);
        };
    }, [, dependencies]);

}